@import "../../../../styling/scss-variables";

.container {
  color: $on-primary2;
  background-color: #2c4067 !important;

  .box {
    max-width: 35%;
    background: #0a2040;
    border: 1px solid $primary-color2;
    box-sizing: border-box;
    border-radius: 1.5rem;
    padding: 2.6rem;
    min-width: 30%;
    max-width: 35%;
    min-height: 65%;
    position: relative;

    .main {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      height: 100%;

      p {
        font-size: 1.1rem;
        margin-top: 2rem;
      }

      .commonbtnContainer {
        position: absolute;
        bottom: 7%;
        left: 50%;
        width: 85%;
        transform: translateX(-50%);
        display: flex;
      }
      .premiumBtns {
        justify-content: space-between;
      }
      .exclusiveBtns {
        justify-content: center;
      }
    }

    .mainContainer {
      display: flex;
      flex-direction: column;
      height: 70%;
      justify-content: center;

      button {
        min-width: 15rem;
        border-radius: 4px;
        padding: 1rem 2rem;
        height: 3.5rem;
        font-size: 1rem;
      }
      .btn2 {
        border: 3px solid $primary-color2;
        background-color: transparent;

        &:hover {
          background-color: $primary-color2;
          color: $on-primary;
        }
      }

      .img {
        width: 75%;
      }
    }
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .container {
    .box {
      min-width: 70%;
      max-width: 70%;

      .main {
        button {
          font-size: 1rem;
          min-width: unset;
        }
      }
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .container {
    .box {
      min-width: 100%;
      max-width: 100%;
      min-height: 100%;
      border: none;
      border-radius: unset;

      .mainContainer {
        .img {
          width: 100%;
        }
        button {
          min-width: unset;
          padding: 1rem;
        }
        .btn2,
        .btn3 {
          min-width: 100%;
        }
      }
    }
  }
}
