@import "../styling/scss-variables";

.themeSwitch {
  transform: rotateZ(90deg);
  position: fixed;
  top: 50%;
  z-index: 4;
}

.OTPContainer {
  color: $on-primary2;
  background-color: #2c4067 !important;

  .box {
    background: #0a2040;
    border: 1px solid $primary-color2;
    box-sizing: border-box;
    border-radius: 1.5rem;
    padding: 2.6rem;
    min-width: 35%;
    max-width: 40%;
    position: relative;
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .OTPContainer {
    .box {
      min-width: 70%;
      max-width: 70%;
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .OTPContainer {
    .box {
      min-width: 100%;
      max-width: 100%;
      min-height: 100%;
      border: none;
      border-radius: unset;

      button {
        min-width: 100%;
      }
    }
  }
}
