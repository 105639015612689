@import "../../styling/scss-variables";

.tagsWrapper {
  display: flex;
  overflow-x: auto;
  padding-bottom: 1.25rem;
  padding-left: 6%;
  padding-top: 5.1rem;
  // position: sticky;
  // position: -webkit-sticky;
  position: relative;
  top: 50px; /* required */
  z-index: 5;
  background-color: $primary-color;

  // .tagCardSmall {
  //   height: 84px !important;
  //   aspect-ratio: 266/114;
  //   transition: height 0.2s ease-in-out;

  //   .bgImg {
  //     height: 84px !important;
  //     aspect-ratio: 266/114;
  //     transition: height 0.2s ease;
  //   }
  // }

  .tagCard {
    margin: 0.5625rem 1.25rem 0.5625rem 0;
    height: 114px;
    // aspect-ratio: 266/114;
    // width: 266px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.5rem;
    color: $on-primary;
    position: relative;
    border-radius: 1rem;
    cursor: pointer;
    user-select: none;
    padding: 0.625rem 1.5625rem !important;
    white-space: nowrap;
    box-sizing: border-box;
    overflow: hidden;
    // width: 100%;
    // background: $primary-color;
    &:hover {
      transform: scale(1.02) !important;
      transition: all 0.2s;
    }

    .bgImg {
      object-fit: cover;
      position: absolute;
      flex: 1;
      display: flex;
      height: 7.125rem;
      aspect-ratio: 266/114;
      z-index: -1;
      border-radius: 1rem;
      opacity: 0.8;
      width: 100%;
      min-height: 3.125rem;
      filter: brightness(0.8);
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .tagsWrapper {
    // top: calc(67px + 18px) !important;
    // top: 1.5625rem !important;
    top: 0 !important;
  }
}
