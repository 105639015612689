@import "../../styling/scss-variables";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  box-sizing: border-box;
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  z-index: 9;
  background: $primary-color;

  .brandName {
    font-size: 2rem;
    display: flex;
    align-items: center;
    color: $on-primary;
    font-weight: bold;
    cursor: pointer;
    img {
      width: unset !important;
      min-width: unset !important;
      margin-right: 1.125rem !important;
      max-height: 2.6rem;
    }
    .breadcrum {
      border-left: 2px solid #fff;
      padding-left: 2.1875rem;
      margin-left: 1.75rem;
      font-size: 1.25rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      // text-transform: uppercase;

      img {
        margin-left: 1.25rem;
      }
    }
  }
  .navSection {
    display: flex;
    align-items: center;
    .navLinks {
      list-style: none;
      display: flex;
      a {
        text-decoration: none;
        margin: 0 1.5rem;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.25rem;
        color: $on-primary;
      }

      .active {
        text-decoration: underline;
        text-underline-offset: 0.8125rem;
      }
    }
  }

  .userLinks {
    display: flex;
    position: relative;
    align-items: center;

    .userDropDown {
      position: absolute;
      display: none;
      bottom: -9rem;
      right: 0;
      width: 100%;
      padding: 1rem;
      border-radius: 0.3125rem;
      background: $primary-color;
      color: $on-primary;
      border: 1px solid $on-primary;
      min-width: 12rem;
      z-index: 21;

      .userDropDownList {
        padding: 0.5rem;
        font-size: 1rem;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;
      }
    }
    .drawerWrapper {
      width: 30%;
      height: 100vh;
      position: fixed;
      animation: slideIn 0.3s ease-in-out;
      box-sizing: border-box;
      z-index: 22;
      right: 0;
      padding: 0 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-shadow: -4px 10px 17px 4px rgba(0, 0, 0, 0.58);
      -webkit-box-shadow: -4px 10px 17px 4px rgba(0, 0, 0, 0.58);
      -moz-box-shadow: -4px 10px 17px 4px rgba(0, 0, 0, 0.58);

      top: 0;
      background-color: $primary-color;
      .header {
        padding: 1rem 0;
        font-size: 1.2rem;
        color: $secondary-color2;
        display: flex;
        justify-content: right;
        gap: 1rem;
        align-items: center;
      }
      .userProfile {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 2rem;
        gap: 2rem;
        position: relative;
        .userDesc {
          color: #fff;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          .username {
            display: flex;
            align-items: center;
            font-size: 1.75rem;
            font-weight: 700;
            color: $primary-color2;
          }
          .profile {
            font-size: 1.4rem;
          }
          .editBtn {
            margin-top: 0;
            width: 11rem;
          }
        }
      }
      .routesSection {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        padding: 2rem 0;
        .routeBox {
          display: grid;
          place-content: center;
          width: 25%;
          height: 7rem;
          border-radius: 12px;
          border: 1px solid $primary-color2;
          cursor: pointer;
          .route {
            display: flex;
            flex-direction: column;
            color: $secondary-color2;
            text-align: center;
            padding: 0 0.4rem;
            gap: 0.7rem;
            align-items: center;
          }
        }
      }
      .badgesWrappper {
        display: flex;
        flex-direction: column;
        position: relative;

        .header {
          color: $primary-color2;
          font-size: 2rem;
          font-weight: 700;
          margin-right: auto;
          padding: 1rem 0;
        }
        .badgesList {
          display: flex;
          overflow: scroll;
          gap: 1rem;
          .badge {
            cursor: pointer;

            img {
              width: 10rem;
            }
          }
        }
      }
      .loginBtn {
        width: 40%;
        margin: 2rem auto;
        display: grid;
        color: $secondary-color2;
        font-size: 1.4rem;
        border-radius: 8px;
        cursor: pointer;
        place-content: center;
        margin-top: 1rem;
        padding: 1.5rem 0.5rem;
        background-color: $logout-color;
      }
    }
  }
  .searchIcon {
    margin-left: 3.0625rem;
  }

  .signInIcon {
    margin-left: 3.0625rem;
    text-transform: uppercase;
    color: $on-primary;
    font-weight: bold;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      margin-left: 0.9375rem !important;
      width: unset !important;
      min-width: unset !important;
    }
    .namedProfileIcon {
      padding: 0.6rem;
      margin-left: 0.3rem;
      height: 1.5rem;
      width: 1.7rem;
      font-size: 0.7rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary-color2;
    }
  }
}

.fixedHeader {
  background: $primary-color;
  // background: rgba(44, 64, 103, 0.2);
  // backdrop-filter: blur(100px);
  // -webkit-backdrop-filter: blur(100px);
}

@keyframes slideIn {
  0% {
    transform: translate(30vw, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes dropDown {
  0% {
    transform: translate(0, -15rem);
  }
  100% {
    transform: translate(0, 0);
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .wrapper {
    padding: 0 1.3125rem !important;
    height: 4.6875rem !important;
    // background: rgba(44, 64, 103, 0.2);
    // backdrop-filter: blur(100px);
    // -webkit-backdrop-filter: blur(100px);
    // border: 1px solid rgba(44, 64, 103, 0.2);
    z-index: 10;
    .navLinks {
      display: none !important;
    }
    .searchIcon,
    .signInIcon {
      margin-left: 1.25rem !important;
    }
    .menuIcon {
      display: block !important;
      margin-left: 0.3125rem;
    }
    .brandName {
      font-size: 1.875rem;
    }
    .signInBtn {
      display: none !important;
    }
    .userLinks {
      .searchWrapper {
        .searchForm {
          padding: 0 2rem;
          gap: 1rem;

          input {
            display: flex;
            background: #000;
            margin: auto;
            width: 100%;
          }
        }
      }
      .userDropDown {
        min-width: 13rem;
      }
      .drawerWrapper {
        width: 100%;
        .userProfile {
          padding: 0 1rem;

          .userDesc {
            .username {
              font-size: 1.4rem;
            }
            .profile {
              font-size: 1rem;
            }
            .editBtn {
              font-size: 1rem;
            }
          }
        }
        .routesSection {
          .routeBox {
            .route {
              display: flex;
              flex-direction: column;
              color: $secondary-color2;
              text-align: center;
              padding: 0 0.4rem;
              gap: 0.7rem;
              font-size: 0.8rem;
              align-items: center;
            }
          }
        }

        .badgesWrappper {
          .header {
            font-size: 1.4rem;
          }
        }
        .loginBtn {
          font-size: 1rem;
        }
      }
    }
  }
  .breadcrum {
    display: none !important;
  }
}

@media only screen and (orientation: landscape) {
  .fixedHeader {
    padding: 1rem;
  }
}
