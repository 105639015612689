@import "../../styling/scss-variables";

.bannerWrapper {
  padding-bottom: 1rem;

  .bannerCard {
    // min-width: 80.3rem;
    // min-width: 50vw;
    width: 9.375rem;
    min-width: 60vw;
    height: 60vh;
    max-height: 55vh;
    display: flex;
    margin-right: 10rem;
    flex: 1;
    // height: 20rem;
    cursor: pointer;

    &:hover {
      transform: scale(1.01) !important;
      transition: all 0.2s;

      .watchBg {
        animation: spin 5s linear infinite;

        @-moz-keyframes spin {
          100% {
            -moz-transform: rotate(360deg);
          }
        }
        @-webkit-keyframes spin {
          100% {
            -webkit-transform: rotate(360deg);
          }
        }
        @keyframes spin {
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
      }

      .playBtn {
        -webkit-transform: translate(-40%, -50%) scale(0.9);
        transform: translate(-40%, -50%) scale(0.9);
      }
    }
  }

  .bannerTilesWrapper {
    display: flex;
    overflow-x: scroll;
    padding-left: 6rem;
    padding-top: 4.5rem;
    padding-bottom: 3rem;
    will-change: filter;
    overflow-y: hidden;
    padding-right: 10rem;
    z-index: 100;
    max-width: 100vw;
    .tileCommonStyle {
      background: $banner-tile;
      // border-radius: 0.6rem 0 0 0.6rem;
      // width: 50.75rem;
      flex: 1;
      height: 100%;
      // aspect-ratio: 810/505;
      position: relative;
      will-change: filter;

      &:last-child {
        margin-right: 0rem !important;
      }
    }

    .imageCard {
      border-radius: 0.6rem 0 0 0.6rem;
    }

    .normalCard {
      border-radius: 0.6rem;
    }

    .tileImage {
      // background-color: #eb4547;
      height: 100%;
      aspect-ratio: 1;
      position: relative;
      border-radius: 0 50% 50% 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .docImg {
        position: absolute;
        inset: unset !important;
        bottom: 0 !important;
        left: -0.9375rem !important;
        height: 110% !important;
        width: unset !important;
        min-width: unset !important;
        max-height: unset !important;
      }

      .dOverlay {
        // height: 80% !important;
        height: 100% !important;
        min-height: unset !important;
        border-radius: 0 50% 50% 0;
      }
    }

    .btnWrapper {
      position: relative;
      -webkit-filter: drop-shadow(5px 5px 5px #2c4067);
      filter: drop-shadow(5px 5px 5px #2c406740);
    }
    .activeBtn {
      position: absolute;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: calc(-9.3rem / 2);
      // top: calc(9.3rem / 2);
      height: 100%;
    }

    .playBtn {
      position: absolute;
      left: 50% !important;
      top: 50% !important;
      transform: translate(-40%, -50%);
      -webkit-filter: drop-shadow(5px 5px 5px #2c4067);
      filter: drop-shadow(5px 5px 5px #2c406740);
      width: 40% !important;
      min-width: unset !important;
      height: unset !important;
      margin: unset !important;
    }
  }
  .tileControls {
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top: 1.5rem;
    user-select: none;
    // .count {
    //   height: 2.75rem;
    //   width: 2.75rem;
    //   border: 1px solid $on-primary;
    //   border-radius: 50%;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   margin: 0.5rem;
    //   cursor: pointer;
    //   color: $on-primary;
    // }
    // .activeCount {
    //   background: #eb4547;
    // }
    .count {
      margin: 0.5rem;
      cursor: pointer;
      width: 0.5rem;
      height: 0.5rem;
      background: #c4c4c4;
      border-radius: 1.4rem;
      color: transparent;
      border: none;
    }
    .activeCount {
      width: 0.75rem !important;
      height: 0.75rem !important;
      background: #eb4547 !important;
    }
  }
}

@media screen and (max-width: 1550px) {
  .bannerCard {
    height: 50vh !important;
    max-height: 50vh !important;
    margin-right: 14rem !important;
  }
}
@media screen and (max-width: 1366px) {
  .bannerWrapper {
    .bannerTilesWrapper {
      padding-top: 2.5rem;
    }
  }
  .bannerCard {
    min-width: 55vw !important;
    margin-right: 14rem !important;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .bannerWrapper {
    margin: 0 5%;
    padding-bottom: 0 !important;
    .bannerCard {
      display: flex;
      min-width: 100% !important;
      margin-right: 1.25rem !important;
      position: relative;
      height: 35vh !important;
      max-height: 40vh !important;

      // width: 100% !important;
      .tileImage {
        position: absolute !important;
        height: 63% !important;
        right: 0;
        top: -6%;
        justify-content: flex-end !important;

        .docImg {
          bottom: 1.125rem !important;
          left: 1.6875rem !important;
          height: 90% !important;
        }
        .dOverlay {
          height: 80% !important;
        }
      }
    }
    .btnWrapper {
      position: absolute;
      bottom: -0.6875rem;
      right: -3.75rem;
    }
  }
  .bannerTilesWrapper {
    padding-left: 0 !important;
    padding-top: 6.25rem !important;
    padding-right: 0 !important;
  }
  .tileCommonStyle {
    width: 100% !important;
    // max-width: 20.4rem !important;
    // height: 17.81rem !important;
    aspect-ratio: 337/245;
    margin-bottom: 4.3rem !important;
    // margin-right: 0.6rem !important;
    // border-radius: 0.6rem 25% 0.6rem 0.6rem !important;
  }
  .imageCard {
    border-radius: 0.6rem 25% 0.6rem 0.6rem !important;
  }

  .normalCard {
    border-radius: 0.6rem;
  }
  .activeBtn {
    height: 6.5rem !important;
    width: 6.5rem !important;
    top: 80% !important;
    left: 60% !important;
    transform: scale(0.7) !important;
  }
  .tileControls {
    .count {
      width: 0.5rem !important;
      height: 0.5rem !important;
      background: #c4c4c4 !important;
      border-radius: 1.4rem !important;
      color: transparent !important;
      border: none !important;
    }
    .activeCount {
      width: 0.75rem !important;
      height: 0.75rem !important;
      background: #eb4547 !important;
    }
  }
}
@media only screen and (max-width: 380px) {
  .bannerWrapper {
    .bannerCard {
      height: 43vh !important;
      max-height: 43vh !important;
    }
  }
}
@media only screen and (max-width: 360px) {
  .bannerWrapper {
    .bannerCard {
      height: 40vh !important;
      max-height: 40vh !important;
    }
  }
}
