.container {
  z-index: 100;
  position: fixed;
  background-color: #2c4067;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw * 16 / 9) !important;
  height: calc(100vh) !important;
}
