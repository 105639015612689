@import "../../styling/scss-variables.scss";

.cardAndImgWrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  .speakers {
    margin-left: 2.375rem;
    margin-top: 2.125rem;
    position: relative;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .speakerImg,
    .remainCount {
      height: 3rem;
      width: 3rem;
      background: #000;
      border-radius: 50%;
    }

    .showAll {
      margin-left: calc(64px * 5);
      font-weight: bold;
      font-size: 20px;
      color: $on-primary;
      text-decoration: underline;
      cursor: pointer;
    }

    .remainCount {
      position: absolute;
      left: calc(3rem * 4 + 0.125rem);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 1.25rem;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      background: linear-gradient(
        180deg,
        rgba(43, 214, 185, 0.17) -100%,
        #eb4547 100%
      );
    }
  }
}

.container {
  position: relative;
  .plusIconLight {
    position: absolute;
  }
}

.webinarWrapper {
  display: flex;
  overflow-x: auto;
  padding-right: 37.1875rem !important;
  overflow-y: hidden;
  padding: 0.625rem 0;
  padding-left: 10rem;

  .upcomingCard {
    aspect-ratio: 37.1875/27.5;
    max-height: 27.5rem;
    min-width: 37.1875rem;
    position: relative;
    display: flex;
    padding: 2.375rem;
    font-size: 1.25rem;
    line-height: 134%;
    z-index: 2;
    margin-left: 2rem;
    border-radius: 1.5rem;
    flex-direction: column;
    justify-content: space-evenly;
    color: $secondary-color2;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      transform: scale(1.05) !important;
      transition: all 0.2s;

      .attendBtn {
        border: 3px solid $secondary-color2 !important;
        transform: scale(1.01) !important;
      }
    }

    &:first-child {
      margin-right: 3.125rem;
    }
    .bgImg {
      position: absolute;
      z-index: -1;
    }

    .count {
      font-weight: 200;
      font-size: 4.375rem;
      line-height: 134%;
      margin-bottom: 2.25rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      margin-top: 1.5625rem;
      font-weight: bold;
      font-size: 1.8125rem;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .subtitle {
      margin-top: 0.625rem;
      font-weight: normal;
      font-size: 1rem;
    }

    .actionBtns {
      margin-top: 1.25rem;
      font-size: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .leftWrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .shareBtn {
          margin-left: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-size: 1.25rem;
          // text-decoration: underline;
          > img {
            margin-right: 0.625rem;
          }
          &:hover {
            transform: scale(1.05);
            transition: transform 0.2s ease-in-out;
          }
        }
      }
      .attendBtn {
        width: 7.625rem;
        height: 3.375rem;
        border: 2px solid $secondary-color2;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: bold;

        &:hover {
          background: $secondary-color2;
          color: #eb4548;
        }
      }

      .saveBtn {
        cursor: pointer;
      }

      .unsaved {
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .webinarWrapper {
    padding: 0.625rem !important;

    .actionBtns {
      font-size: 0.875rem !important;
      margin-top: 1.5rem !important;

      .attendBtn {
        width: 6rem !important;
        height: 2.25rem !important;
      }

      .seats {
        display: none !important;
      }

      .shareBtn {
        font-size: 0.875rem !important;
        img {
          width: 1.5rem !important;
        }
      }
    }

    .speakers {
      margin-left: 0.9375rem !important;
      height: 2.4375rem !important;

      .speakerImg,
      .remainCount {
        height: 2.4379rem !important;
        width: 2.4375rem !important;
      }

      .remainCount {
        left: calc(29px * 2);
        font-size: 0.875rem !important;
      }

      .showAll {
        font-size: 0.875rem !important;
        margin-left: calc(2.4375rem * 3);
      }
    }

    .upcomingCard {
      aspect-ratio: 265/312 !important;
      max-width: 16.5625rem !important;
      height: 19.5rem !important;
      min-width: 16.5625rem !important;
      background-repeat: no-repeat !important;
      padding: 0.875rem !important;
      font-size: 0.625rem !important;
      background-size: cover !important;

      &:first-child {
        margin-left: 0.9375rem !important;
      }
      &:first-child {
        margin-right: 0.625rem;
      }

      .count {
        font-size: 2rem !important;
        margin-bottom: 5.375rem !important;
      }
      .title {
        font-size: 0.875rem !important;

        > img {
          width: 10%;
        }
      }
      .subtitle {
        display: none;
      }
    }
  }
  .sliderControls {
    transform: scale(0.75);
  }
}
