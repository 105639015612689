@import "../../../styling/scss-variables";
.container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $primary-color;
  padding: 2% 5%;
  color: #fff;

  .profileHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .heading {
      font-size: 1.8rem;
    }
    .closeBtn {
      cursor: pointer;
    }
  }
  .inputsContainer {
    overflow: auto;
    height: 80vh;
    &::-webkit-scrollbar {
      display: block !important;
    }
    .inputContainer {
      margin-bottom: 1.5rem;
      label {
        font-size: 1.2rem;
      }
      input,
      select {
        margin-top: 1rem;
      }

      select {
        color: #888888;

        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url(../../../assets/images/down.png);
        background-repeat: no-repeat;
        background-position-x: 97%;
        background-position-y: 50%;
        cursor: pointer;

        option:not(:first-of-type) {
          color: #fff;
          background: rgba(44, 64, 103, 1);
        }
      }
    }
  }
}
