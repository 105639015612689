@import "../../../../styling/scss-variables";

.containerBottom {
  position: absolute;
  left: 0%;
  bottom: 5%;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;

  .interactionContainer {
    border: 1px solid $on-primary;
    background: rgba(0, 0, 0, 0.47);
    box-shadow: 0px 0px 7.52381px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(34px);
    border-radius: 6.35rem;
    display: flex;
    align-items: center;
  }
}
.containerRight {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  right: 4%;
  flex-direction: column;

  .interactedPeople {
    background: rgba(0, 0, 0, 0.47);
    border: 1px solid $on-primary;
    box-shadow: 0px 0px 7.52381px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(34px);
    border-radius: 6.35rem;
    display: flex;
    flex-direction: column;
  }
}
