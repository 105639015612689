@import "../../styling/scss-variables";

// .shine {
//   background: $shimmer-bg;
//   background-image: linear-gradient(
//     to right,
//     $shimmer-color2 4%,
//     $shimmer-color3 25%,
//     $shimmer-color4 36%
//   );
//   background-repeat: no-repeat;
//   display: inline-block;
//   position: relative;

//   -webkit-animation-duration: 1s;
//   -webkit-animation-fill-mode: forwards;
//   -webkit-animation-iteration-count: infinite;
//   -webkit-animation-name: placeholderShimmer;
//   -webkit-animation-timing-function: linear;
//   animation: placeholderShimmer 1s linear 0 infinite forwards forwards;
// }

//Diagonal
.shine {
  background: $shimmer-bg;
  background-image: linear-gradient(
    135deg,
    $shimmer-color2 30%,
    $shimmer-color3 50%,
    $shimmer-color4 70%
  );
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  // animation: placeholderShimmer 1s linear 0 infinite forwards forwards;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

.shinelessBox {
  background: $surface;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
}

.box {
  border-radius: 6px;
}

.circle {
  border-radius: 50%;
}

.line {
  border-radius: 4px;

  &:nth-child(n) {
    margin-top: 10px;
  }
  &:nth-child(1) {
    margin-top: 0px;
  }
}

.lines {
  display: flex;
  flex-direction: column;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}