@import "../../styling/scss-variables";

.chatBotContainer {
  z-index: 15;
  background: #d9524e;
  position: fixed;
  right: 0;
  bottom: 0;
  cursor: pointer;
  right: 2rem;
  bottom: 2rem;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  padding: 1.5rem;
  transition: 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
// .list__container {
//   height: 90%;
//   overflow: scroll;
// }
.chatContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
  background: white;
  margin-right: 4rem;
  margin-bottom: 4rem;
  width: 24.5625rem;
  height: 35.625rem;
  background: #222222;
  box-shadow: 0px -3px 12px rgb(0 0 0 / 25%);
  border-radius: 1rem;
  transition: 1s ease-in-out;
  .chatBotContainerDiv {
    height: 85%;
    overflow: auto;
    &::-webkit-scrollbar {
      display: block !important;
    }
  }
}

.ChatBotHeader {
  background: #161616;
  padding-top: 0.5rem;
  border-radius: 0.8rem;
}

.ChatBotHeader__title {
  color: white;
  h2 {
    padding-top: -1rem;
    margin-top: -0.3rem;
    padding: 1rem;
    /* padding-left: 1.3rem; */
    margin-left: 1rem;
  }
}

.ChatConnections {
  // padding: 1rem;
  // padding-top: 0.5rem;

  border-radius: 0.8rem;
  // background: #222222;
  color: white;

  margin-top: 0px;
}

.ChatConnections__title {
  padding-top: 0px;
  margin-left: 2rem;
  margin-top: -1rem;

  /* line-height: 138%; */
  /* display: flex; */
  /* align-items: center; */
  color: rgba(255, 255, 255, 0.49);
  h2 {
    font-weight: 400;
    font-size: 0.9rem;
  }
}

.ChatConnections__user {
  display: flex;
  align-items: center;
  // padding: 1rem;
  margin-left: 2rem;
  margin-top: 1rem;
}

.ChatConnections__user__img {
  margin-right: 1rem;
  img {
    width: 3rem;
    height: 3rem;
  }
}

.ChatConnections__user__name {
  display: flex;
  flex-direction: row;
  /* grid-gap: 0.2rem; */
  /* gap: 6.2rem; */
  padding-bottom: 0.5rem;
  justify-content: space-between;
  align-items: center;
  margin-right: 2rem;
  width: 100%;
  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;

    color: #ffffff;
    margin: 0;
  }
}

.ChatConnections__user__name2 {
  display: flex;
  flex-direction: column;
  grid-gap: 0.2rem;
  /* gap: 6.2rem; */
  padding-bottom: 0.5rem;
  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;

    color: #ffffff;
    margin: 0;
  }
}

.userListBox {
  border: 1px solid rgba(255, 255, 255, 0.22);
  margin: 1.5rem;
  border-radius: 0.5rem;
}

.userListBox__title {
  padding-top: 0px;
  margin-left: 1rem;
  margin-top: 1rem;
  h2 {
    font-weight: 400;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.49);
  }
}

.userListBox__userList__user {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.userListBox__userList_user_Connect_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
  button {
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    color: #eb4547;
    background: transparent;
    margin-top: -0.5rem;
    border: 1px solid;
    border-radius: 1.2rem;
    padding: 0.2rem 0.4rem;
  }
}

.userListBox__userList__user_container {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.chatSection__header {
  background: #161616;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.8rem;
  color: white;
  display: flex;
  align-items: center;
}

.chatSection__header__back__btn {
  margin-right: 1rem;
  img {
    width: 1.2rem;
    background: transparent;

    cursor: pointer;
  }
}

.chatSection__input__box {
  width: 95%;
  background: #161616;
  margin-top: 0.5rem;
}

.chatSection__input__box__input {
  margin: 1rem;
  background: #222222;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 2rem;
  margin-left: 2rem;
  border-radius: 0.5rem;

  textarea {
    font-size: 0.8rem;
    width: 100%;
    padding: 0.8rem;
    background: transparent;
    border: none;
    color: white;
    font-weight: 400;
    resize: none;
    &:focus {
      outline: none;
    }
  }
  button {
    background-color: transparent;
    img {
      margin: 0.5rem;
      cursor: pointer;
      width: 2rem;
      height: 2rem;
    }
  }
}

.chatMessagesSection {
  height: 100%;
  overflow: scroll;
  scroll-behavior: smooth;
}

.chat__messages__date {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  h4 {
    font-size: 0.8rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.49);
  }
}

.chat__messages__Container {
  display: flex;

  flex-direction: column;
  margin-top: -2rem;
}

.chat__messages__left {
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  // margin-top: -2rem;
}

.chat__messages__left__img {
  img {
    width: 3rem;
    height: 3rem;
  }
}
.ChatConnections__user__img {
  position: relative;
}
.ChatConnections__user__img_online::after {
  position: absolute;
  top: 0;
  right: 0;
  content: " ";
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: rgb(6, 214, 6);
  border: solid 2px #222;
}
.chat__messages__left__text {
  padding: 1rem;
  width: 55%;
  background-color: black;
  color: white;
  margin-left: 4.5rem;
  margin-top: -2rem;
  position: relative;
  border-radius: 0 1rem 1rem 1rem;
  font-size: 0.8rem;
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: -4.5%;
    -webkit-clip-path: polygon(62% 0, 0 0, 61% 50%);
    clip-path: polygon(62% 0, 0 0, 61% 50%);
    color: white;
    height: 2rem;
    background: black;
    width: 2rem;
  }
  p {
    overflow-wrap: break-word;
  }
}

.chat__messages__left__again {
  display: flex;
  flex-direction: column;
}

.chat__messages__left__text__again {
  padding: 1rem;
  width: 51.5%;
  background-color: black;
  color: white;
  margin-left: 6rem;
  margin-top: 0.5rem;
  position: relative;
  border-radius: 1rem;
  font-size: 0.8rem;
  p {
    overflow-wrap: break-word;
  }
}

.chat__messages__right {
  margin-right: 1rem;
}

.chat__messages__right__text {
  background: black;
  color: white;
  // margin-top: 10px;
  width: 55%;
  padding: 1rem;
  background: black;
  color: white;
  margin-right: 4.5rem;
  margin-top: 0.5rem;
  position: relative;
  border-radius: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;

  /* or 33px */

  color: #ffffff;
  p {
    overflow-wrap: break-word;
  }

  &::after {
    position: absolute;
    content: "";
    top: 0%;
    right: -5%;
    -webkit-clip-path: polygon(100% 0, 0 0, 0 96%);
    clip-path: polygon(100% 0, 0 0, 0 76%);
    color: white;
    height: 2rem;
    background: black;
    width: 2rem;
  }
}

.chat__messages__right__img {
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-end;
  img {
    width: 3rem;
    height: 3rem;
  }
}

.chat__message__right__again {
  margin-right: 4rem;
}

.chat__messages__right__text__again {
  background: black;
  color: white;
  // margin-top: 10px;
  width: 63%;
  padding: 1rem;
  background: black;
  color: white;
  margin-right: 1.5rem;
  margin-top: 0.5rem;
  position: relative;
  border-radius: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;

  /* or 33px */

  color: #ffffff;
  p {
    overflow-wrap: break-word;
  }
}

.chat__messages_right__text_container__again {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  // overflow: scroll;
}

.chat__messages_right__text_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -1.5rem;
}

.ChatConnections__user__showMore {
  display: flex;
  justify-content: space-around;
  button {
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    color: #eb4547;
    background: transparent;
    margin-top: -0.5rem;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .chat__messages__right__text__again {
    width: 61%;
  }
  .chatContainer {
    height: 100%;
    width: 100%;
    margin: 0;
  }
  .ChatConnections__user__name {
    h3 {
      font-size: 0.8rem;
    }
  }
  .ChatConnections__user__img {
    img {
      width: 3rem;
      height: 3rem;
    }
  }
  .userListBox__userList_user_Connect_container {
    button {
      font-size: 0.8rem;
    }
  }
  // .list__container {
  //   height: 86%;
  //   overflow: scroll;
  // }
  .chat__messages__right {
    margin-right: 1rem;
    margin-top: 1rem;
  }
}

.User_loader {
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 100%;
  height: 100%;
  color: white;
  padding-top: 5rem;
  padding-bottom: 5rem;
  img {
    width: 3rem;
    height: 3rem;
  }
}

.chat__messages__right__text__timing {
  display: flex;
  justify-content: flex-end;
  font-size: 0.6rem;
  margin-bottom: -0.5rem;
  color: grey;
}
