@import "../../../../styling/scss-variables";

.tileCard {
  padding: 3.1rem 0 0 6rem;
  height: 100%;
  color: $on-primary;
  font-size: 1.5vw;
  box-sizing: border-box;

  .dataWrapper {
    display: flex;
    justify-content: space-between;
    // padding-right: 30px;
    margin-top: 1.5rem;
    position: relative;
  }
  .columnView {
    display: flex;
    flex-direction: column;
  }

  .rowView {
    flex-direction: row;
    display: flex;
    .mobName {
      margin-right: 45px;
    }
  }
  .title {
    font-weight: bold;
    font-size: 2.5vw;
    line-height: 3.6vw;
    text-transform: capitalize;
    margin-bottom: 1vw;
  }
  .mobName {
    display: none;
  }

  .titleBig {
    font-size: 4vw;
    max-width: 15rem;
    margin-right: 8.7rem;
    line-height: 6vw;
  }

  .docImg {
    width: 20rem;
    height: 23rem;
    object-fit: contain;
    position: absolute;
    right: 2rem;
    // top: -10rem;
    top: -3rem;
  }

  .dataImgWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dataImg {
    width: 30rem;
    max-height: 100%;
  }

  .subtitleAndInfo {
    max-width: 22.25rem;
    font-size: 1.3vw;
    line-height: 1.8vw;

    .info {
      margin-top: 2rem;
      font-size: 1rem;
      .episodes {
        margin-right: 4.3rem;
      }
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .rowView {
    flex-direction: column !important;
    .mobName {
      margin-right: 0 !important;
    }
    .episodes {
      margin-right: 25px !important;
    }
  }
  .titleBig {
    width: 100% !important;
    max-width: unset !important;
    margin-top: 10px !important;
    line-height: 3.6rem !important;
  }
  .titleBig,
  .title {
    font-size: 36px !important;
  }
  .dataImgWrapper {
    align-items: flex-end !important;
  }
  .docImg {
    width: 172.44px !important;
    height: 167.17px !important;
    object-fit: contain;
    position: absolute;
    right: 0 !important;
    top: -100px !important;
  }
  .tileCard {
    font-size: 12px !important;
    align-items: flex-end;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    padding: 0 !important;
    .dataWrapper {
      padding-top: 50px !important;
      margin-top: 0 !important;
    }
    .webName {
      display: none;
    }
    .mobName {
      display: block;
    }
    .title {
      width: 50% !important;
      line-height: 40px !important;
      margin: 10px 0 !important;
    }
    .titleBig {
      font-size: 40px !important;
      width: 100% !important;
    }
    .dataImg {
      width: 29rem;
      height: 100%;
    }
  }
  .subtitleAndInfo {
    max-width: 100% !important;
    font-size: 16px !important;
    line-height: 23px !important;
    .info {
      flex-direction: row !important;
      font-size: 10px !important;
      margin-top: 15px !important;
      margin-right: 35px !important;
    }
  }
}
@media only screen and (max-width: 360px) {
  .tileCard {
    .dataImg {
      width: 26rem;
    }
  }
}
