@import "../../../../styling/scss-variables";

.resourcesWrapper {
  margin-top: 1.25rem;
  .card {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 2rem;
    color: $on-primary;
    margin-bottom: 64px;

    &:last-of-type {
      margin-bottom: 0;
    }
    .image {
      width: 14.3125rem;
      min-width: 14.3125rem;
      height: 13.3125rem;
      margin-right: 3.125rem;
      display: flex;

      img {
        width: 100%;
        object-fit: cover;
        height: auto;
        border-radius: 0 50% 50% 0;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      min-height: 13.3125rem;
      margin-right: 1rem;

      .subtitle {
        padding: 1rem 0;
        padding-bottom: 2rem;
        font-weight: normal;
        font-size: 1.25rem;
        width: 60%;
        color: #c5c2c2;
      }
      .actionBtns {
        display: flex;
        font-weight: bold;
        font-size: 1.25rem;

        img {
          margin-right: 1rem;
        }

        .downloadBtn,
        .shareBtn {
          border-radius: 8px;
          padding: 1rem 2rem;
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 1.25rem;
          text-decoration: none;
        }
        .shareBtn {
          color: $on-primary;
        }
      }
      .downloadBtn {
        background: #2c4067;
        margin-right: 2.75rem;
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .image {
    max-width: 7.625rem !important;
    min-width: 7.625rem !important;
    height: 7.75rem !important;
    img {
      width: 100%;
      height: auto;
    }
  }
  .card {
    font-size: 0.875rem !important;
    .subtitle {
      font-size: 0.75rem !important;
      width: 100% !important;
    }
    .info {
      min-height: 7.75rem !important;
    }
    .downloadBtn,
    .shareBtn {
      font-size: 0.75rem !important;
      padding: 0 !important;
    }
    .downloadBtn {
      margin-right: 1.25rem !important;
      padding: 0.5rem 1.25rem !important;
    }
    .actionBtns {
      img {
        width: 1rem !important;
        margin-right: 0.5rem !important;
      }
    }
  }
}
