@import "../../styling/scss-variables";

.webinarDetails {
  background: $primary-color;
  .pageData {
    .details {
      padding: 3rem 10%;
      max-width: 100vw;
      overflow: hidden;
    }
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .column {
    display: flex;
    flex-direction: column;
  }
  .videoSide {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      // max-height: 690px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .qAimg {
      position: absolute;
      right: 4%;
      top: 5%;
      cursor: pointer;
      img {
        max-height: 2.8rem;
        height: 2.8rem;
        max-width: 2.8rem;
        width: 2.8rem;
      }
    }

    .loaderWrapper {
      padding: 0 10%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
    }
  }
}

.tabsData {
  margin-top: 3.5rem;
}

.sectionWrapper {
  padding: 9.375rem 0;
  position: relative;
}
.placeholderWrapper {
  height: 100%;
  width: 100%;
  position: relative;

  .loader {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 6rem;
      height: unset;
    }
  }
}

.OTPContainer {
  color: $on-primary2;
  background-color: #2c4067 !important;

  .box {
    background: #0a2040;
    border: 1px solid $primary-color2;
    box-sizing: border-box;
    border-radius: 1.5rem;
    padding: 2.6rem;
    min-width: 35%;
    max-width: 40%;
    position: relative;
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .OTPContainer {
    .box {
      min-width: 70%;
      max-width: 70%;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .webinarDetails {
    .pageData {
      padding-top: 4.6875rem;
      .details {
        padding: 1.125rem 1rem !important;
      }
    }
    .videoSide {
      .qAimg {
        top: 7%;
      }
    }
  }
  .tabsData {
    margin-top: 1.125rem !important;
  }
  .sectionWrapper {
    padding: 1.25rem 0 !important;
  }

  .OTPContainer {
    .box {
      min-width: 100%;
      max-width: 100%;
    }
  }
}
