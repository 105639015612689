@import "../../../../styling/scss-variables";

.otp-form {
  display: flex;
  flex-direction: column;

  h1 {
    margin: 1rem 0;
  }

  .back-btn {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    color: $primary-color2;
    cursor: pointer;
  }

  .number-text {
    margin-top: 1.5rem;
    font-size: 1.3rem;

    span {
      color: $primary-color2;
      text-decoration: underline;
    }
  }
  .resend-otp,
  .terms {
    span {
      color: $primary-color2;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .terms {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
  }

  .otp-container {
    margin: 1rem 0;
    display: flex;
    height: 5rem;
    gap: 1rem;

    input {
      outline: none;
      border: 1px solid rgba(255, 255, 255, 0.4);
      box-sizing: border-box;
      border-radius: 8px;
      width: 4rem;
      color: $on-primary2;
      font-size: 2.2rem;
      text-align: center;
      padding: 1rem;
      background-color: transparent;

      &:focus {
        outline: none;
      }
    }
  }

  .actionBtns {
    display: flex;

    .cancel-btn {
      margin-left: 1rem;
      background-color: transparent;
      border: 3px solid $primary-color2;
    }
  }
  .next-btn {
  }
  .send-otp-btn {
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .otp-form {
    align-items: center;
    justify-content: center;
    height: 100%;
    .actionBtns {
      flex-direction: column;

      .cancel-btn {
        margin-left: 0;
      }
    }
  }
}
