@import "../../../../../../styling/scss-variables";

.imgContainer {
  position: relative;
  margin: 0.8rem;
  cursor: pointer;
  height: 2rem;
  width: 2rem;

  .img {
    border-radius: 50%;
  }
  .interaction {
    position: absolute;
    bottom: 60%;
    left: 70%;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    img {
      height: 1.1rem;
      width: 1.1rem;
    }
  }
  .connectPeople {
    position: absolute;
    bottom: 125%;
    right: 64%;
    background: #ffffff;
    box-shadow: 0px 0px 6.19048px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    min-width: 9rem;
    padding: 1rem;
    display: none;

    .name {
      color: #1d1d1d;
    }
    .connect {
      color: $primary-color2;
      background-image: linear-gradient(
        transparent 0%,
        transparent 90%,
        $primary-color2 90%,
        $primary-color2 100%
      );
      display: inline-block;
      background-repeat: no-repeat;
      background-size: 0% 100%;
      background-position-x: right;
      transition: background-size 300ms;

      &:hover {
        background-size: 100% 100%;
        background-position-x: left;
      }
    }

    &::after {
      position: absolute;
      content: url(./triangle.png);
      top: 70%;
      right: 0;
    }
  }
  &:hover .connectPeople {
    display: block;
  }
}
