@import "../../../../../../styling/scss-variables";

.imgContainer {
  position: relative;
  padding: 0.8rem;
  display: flex;
  border-right: 1px solid rgba(255, 255, 255, 0.25);
  align-items: center;
  cursor: pointer;
  height: 2.5rem;
  min-width: 4.6rem;
  color: $on-primary;
  transition: all 0.3s ease-in-out;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain !important;
  }
  .count {
    font-weight: 400;
    font-size: 1.2rem;
  }

  &:last-child {
    border: none;
    border-top-right-radius: 1.8rem;
    border-bottom-right-radius: 1.8rem;
  }
  &:first-child {
    border-top-left-radius: 1.81rem;
    border-bottom-left-radius: 1.81rem;
  }

  .tooltiptext {
    width: 7.5rem;
    background-color: $tooltip-color;
    color: #1d1d1d;
    text-align: center;
    padding: 0.3125rem 0;
    border-radius: 0.375rem;
    text-transform: capitalize;
    display: none;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    bottom: 120%;
    left: -15%;
    z-index: 2;
    font-size: 1rem;

    &::after {
      content: " ";
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      left: 50%;
      transform: translateX(-50%);
      border-width: 0.6rem;
      border-style: solid;
      border-color: $tooltip-color transparent transparent transparent;
    }
  }
  &:hover > .tooltiptext {
    display: block;
  }
  &:hover {
    background-color: #fff;
    color: $primary-color2;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .imgContainer {
    height: 1.5rem;
    min-width: 3rem;
    align-items: flex-end;
    justify-content: center;
    .count {
      font-size: 1rem;
    }
  }
}
