@import "../../styling/scss-variables.scss";

.dropdownWrapper {
  padding-left: 10%;
  position: sticky;
  z-index: 8;
  //   display: inline-flex;
  // background-color: $primary-color;
  ul {
    margin: 0;
    min-width: max-content;
    li {
      &:hover {
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
  }
}

.listItems {
  position: absolute;
  list-style: none;
  background: #39517f;
  z-index: 10;
  padding: 1.125rem;
  color: #fff;
  font-size:1.525rem;
  line-height: 2.5rem;
  cursor: pointer;
  user-select: none;
  //   box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  height: auto;
  max-height: calc(55 * 0.625rem);
  overflow-y: auto;
  top: 3.125rem;
  border-radius: 1rem;
  //   width: 100%;
}

.activeCategory {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 2.5rem;
  color: $on-primary;
  cursor: pointer;
  user-select: none;

  &:hover {
    .listItems {
      display: block !important;
    }
  }
}

.arrow {
  width: 0.9375rem;
  height: 0.9375rem;
  transform: rotate(45deg);
  margin-left: 1.25rem;
  margin-top: 0.625rem;
}

.tickImg {
  width:1.525rem;
}

@media only screen and (max-width: $mobile-breakpoint) {
  .activeCategory {
    font-size: 0.875rem !important;
    color: #fff !important;
  }
  .arrow {
    // margin-top: 0 !important;
    width: 0.625rem !important;
    height: 0.625rem !important;
    border-width: 2px !important;
  }
  .listItems {
    font-size: 1.125rem !important;
    line-height: 1.875rem!important;
    top: 1.875rem!important;
    max-height: 21.875rem !important;
  }
}
