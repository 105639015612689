@import "../../../../styling/scss-variables";

.customTabsWrapper {
  background-color: #222;

  .customTabHeader {
    margin-bottom: 3.25rem !important;
    background-color: #161616;
    height: 4.4375rem;
    user-select: none;
    position: relative;

    .customTabClass {
      border-bottom-color: #eb4547 !important;
      margin-right: 0 !important;
      // height: 50%;
      padding-top: 1.5625rem;
      border-bottom-width: 4px !important;
      padding-bottom: 0.3125rem !important;
      &:first-of-type {
        margin-right: 5rem !important;
      }
    }
  }
}

.pollsWrapper {
  color: #fff;
  font-weight: 600;
  font-size: 1.125rem;
  height: 98%;
  padding-right: 0.5rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: block !important;
  }

  .noVisiblePoll {
    text-align: center;
  }

  .pollCard {
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 0.5rem;
    padding: 1rem 1.25rem;

    .error {
      font-size: 1rem;
      color: #eb4547;
      margin: 0.7rem 0.6rem;
    }
    .pollOption {
      margin-top: 0.625rem;

      .bgDiv {
        height: 2.5rem;
        background: #ffafaf;
        border: 1px solid #eb4547;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        align-items: center;
        font-size: 1rem;
        position: relative;
        margin-bottom: 0.5rem;
      }
      .progress {
        background: #eb4547;
        height: 100%;
      }

      .value {
        position: absolute;
        left: 1.25rem;
      }

      .percent {
        position: absolute;
        right: 1.25rem;
        transition: all 0.5s ease-in;
      }
    }
    .votesValue {
      display: flex;
      justify-content: flex-end;
      color: #eb4547;
      font-size: 0.75rem;
      margin-top: 0.625rem;
    }
    .showResult {
      font-size: 1rem;
      width: max-content;
      height: unset;
      padding: 0.3125rem 0.625rem;
      margin-top: 0.625rem;
    }
  }
}

.qnaWrapper {
  position: relative;
  height: 100%;
  .qcontainer {
    overflow-y: auto;
    height: calc(100% - 8rem);
    padding-right: 0.5rem;

    &::-webkit-scrollbar {
      display: block !important;
    }
    .quesCard {
      color: #fff;
      border: 1px solid rgba(255, 255, 255, 0.3);
      box-sizing: border-box;
      border-radius: 8px;
      padding: 1.5rem;
      font-size: 1rem;
      margin-bottom: 1.25rem;
      .row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        > div:nth-child(2) {
          color: #eb4547;
        }
      }
    }
  }
  .input {
    position: absolute;
    bottom: 4%;
    // width: 563px;
    width: 100%;
    border: 1px solid #c2cedb;
    box-sizing: border-box;
    border-radius: 4px;
    height: 4.6875rem;
    // margin-left: -55px;
    input {
      height: 100%;
      background-color: #222;
      padding: 1.5rem;
      box-sizing: border-box;
      border: none;
      width: calc(100% - 6.5625rem);
      color: #fff;

      &:focus {
        outline-color: transparent;
        outline-style: none;
      }
    }
    button {
      background: #2c4067;
      border-radius: 8px;
      width: 6.25rem;
      height: 3.6875rem;
      color: #fff;
      &:disabled {
        color: #ccc;
      }
    }
  }
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 1.5rem;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .qnaWrapper {
    .input {
      bottom: 0;
    }
  }
}
