@import "../../../../styling/scss-variables";

.webinarHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.25rem;
  color: $on-primary;
  .column {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
  }

  .info {
    font-weight: 300;
    font-size: 1.2rem;
    border-left: 3px solid #eb4547;
    padding-left: 0.625rem;
    margin-top: 0.5rem;
  }

  .detailsBtn {
    position: relative;
    color: #eb4547;
    font-weight: normal;
    font-size: 1.4rem;
    margin-top: 0.5rem;
    text-underline-offset: 2px;
    width: max-content;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .invite {
    position: relative;
  }
  .inviteBtn > button {
    background: $surface;
    border: 2px solid $surface;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1.25rem 2.875rem;
    font-weight: bold;
    font-size: 1.2rem;
    color: $on-primary;
    box-sizing: border-box;
  }
}
.webinarHeaderOverlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  width: 100%;
  top: 0;
}

.actionSection {
  margin-top: 2.9375rem;
  margin-left: calc(6.5625rem + 2rem);
  display: flex;
  font-weight: bold;
  font-size: 1.2rem;
  color: $on-primary;

  .certBtn {
    margin-top: 0;
    padding: 1.25rem 3rem;
    margin-right: 2.1875rem;
  }

  .feedbackBtn {
    cursor: pointer;
    text-underline-offset: 2px;
    &:hover {
      text-decoration: underline;
    }
  }

  .icon {
    margin-right: 1.25rem;
  }
}

.row {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: $mobile-breakpoint) {
  .webinarHeader {
    font-size: 1rem !important;
    line-height: normal;
    align-items: flex-start;
    .info {
      font-size: 0.75rem !important;
    }
    .column {
      margin-left: 0.5rem !important;
    }
    .inviteBtn > button {
      background: none;
      border: 1px solid #fff;
      padding: 0.75rem 1.25rem !important;
      color: #eb4547;
      font-size: 0.75rem !important;
    }
    .detailsBtn {
      font-size: 0.8125rem !important;
    }
  }
  .row {
    // align-items: flex-start;
  }
  .actionSection {
    margin-left: 0 !important;
    margin-top: 1rem !important;
    position: relative;
    .certBtn {
      font-size: 0.75rem !important;
      padding: 0.75rem 1.25rem !important;
    }
    .feedbackBtn {
      border: 1px solid $on-primary;
      padding: 0.75rem 1.25rem;
      font-size: 0.75rem;
      border-radius: 4px;
      img {
        display: none;
      }
    }
  }
}
