@import "../../../../../styling/scss-variables";

.progress {
  width: 80%;
  margin: 3rem auto;

  .step {
    position: relative;
    height: 1.5rem;

    .stepName {
      position: absolute;
      bottom: 150%;
      right: -120%;
    }
    .stepNameVerified {
      font-weight: 600;
      font-size: 1.5rem;
    }

    .stepDash {
      height: 1rem;
      width: 2px;
      top: 50%;
      transform: translateY(-50%);
      position: relative;
    }
    .dot {
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      position: relative;
      background-color: $primary-color2;
      top: 50%;
      transform: translateY(-50%);

      .selectedDot {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        height: 50%;
        width: 50%;
        border-radius: 50%;
      }
    }
  }
  .closeBtn {
    position: absolute;
    top: 4%;
    right: 4%;
    cursor: pointer;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .progress {
    width: 82%;

    .closeBtn {
      top: 2%;
      right: 3%;
    }
  }
}
