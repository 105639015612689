@import "../../../../styling/scss-variables";

.show {
  visibility: visible;
}
.hide {
  visibility: hidden;
}

.feedback {
  position: fixed;
  width: 50%;
  height: auto;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1.5rem;
  z-index: 10;
  font-size: 0.875rem;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid #eb4547;
  backdrop-filter: blur(24px);
  z-index: 21;

  .feedbackSubmit {
    display: flex;
    justify-content: center;
    button {
      width: 80%;
      margin: 1.875rem 1.25rem;
      padding: 0.625rem 1.25rem;
      height: unset;
    }
  }
  .qtnsWrapper {
    max-height: 50vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: block !important;
    }
  }
  .feedbackClose {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1.25rem;
    cursor: pointer;
  }
  .feedbackTitle {
    font-size: 1.8rem;
    margin: 1.875rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
  }
  .Qtns {
    font-size: 1rem;
    margin: 0.625rem 60px;
    line-height: 1.6;
    .optns {
      padding: 0.5rem;
      padding-left: 1.2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;
      gap: 1rem;
      input {
        margin: 0.625rem 1.25rem;
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .show {
    visibility: visible;
    animation-name: slide;
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }
  .hide {
    visibility: hidden;
    animation-name: fade;
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }
  .feedback {
    width: 100% !important;
    bottom: 0% !important;
    top: auto !important;
    // max-height: calc(100% - 300px);
    border: 1px solid #000;
    left: 0px;
    transform: translate(0, 0);
    transition: all 0.5s ease;
    border-radius: 1.5rem 1.5rem 0 0;
    font-size: 0.875rem;
    background-color: #000;
    button {
      width: 90%;
      margin-bottom: 1.25rem;
    }
    .feedbackClose {
      position: absolute;
      top: 0;
      right: 0;
      margin: 1.25rem;
      cursor: pointer;
    }
    .feedbackSubmit {
      background-color: #000;
    }
    .qtnsWrapper {
      background-color: #000;
    }
    .qtnsWrapper::-webkit-scrollbar-track {
      background-color: #fff !important;
      border-radius: 100px;
    }
    .feedbackTitle {
      font-size: 1.8rem;
      border-radius: 1.5rem 1.5rem 0 0;
      margin: 0 0 3.25rem 0 !important;
      padding-top: 1.875rem !important;
      height: 4.4375rem;
      background-color: #161616;
      font-weight: 600;
    }
    .Qtns {
      margin: 0.625rem 1.25rem;
      border: 1px solid rgba(255, 255, 255, 0.3);
      box-sizing: border-box;
      border-radius: 8px;
      padding: 16px 1.25rem;
    }
  }
  @keyframes slide {
    0% {
      opacity: 1;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fade {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: translateY(100%);
    }
  }
}
