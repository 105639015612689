@import "../../styling/scss-variables.scss";

.newsflixWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin: 0 auto;

  .newsFlix {
    color: $on-primary;
    font-size: 1.875rem;
    font-weight: 800;
    > span:first-of-type {
      color: #eb4547;
    }
  }

  .header {
    text-align: left;
  }

  .bigHead {
    font-weight: bold;
    font-size: 5rem;
    color: $on-primary;
    max-width: 40.0625rem;
  }

  .newsWrapper {
    .dataWrapper {
      display: flex;
      margin-top: 4.5625rem;
      cursor: pointer;

      &:hover {
        transform: scale(1.05) !important;
      }
    }
    .newsImg {
      position: relative;
      .mainImg {
        width: 13.09625rem;
        height: 14.455625rem;
        border-radius: 0 50% 50% 0;
      }
    }
    .playBtn {
      position: absolute;
      left: -40%;
      top: 30%;
      cursor: pointer;
      width: 80%;
    }

    .info {
      color: $on-primary;
      margin-left: 3rem;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      justify-content: center;
      color: $grey1;
      font-size: 0.875rem;
      max-height: 14.455625rem;

      :nth-child(1) {
        color: $on-primary;
      }

      :nth-child(2) {
        font-weight: bold;
        font-size: 2.5rem;
        // max-width: 450px;
        max-width: 10ch;
        color: $on-primary;
      }

      :nth-child(3) {
        font-size: 1.25rem;
        max-width: 23.75rem;
        color: #de4143;
        line-height: 142%;
        letter-spacing: -0.02em;
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .newsflixWrapper {
    .header {
      width: 100%;
    }
  }
  .bigHead {
    font-size: 1.5rem !important;
    // max-width: 199px !important;
  }

  .newsImg {
    > img {
      width: 8.75rem !important;
      height: 8.75rem !important;
    }
    .playBtn {
      width: 80% !important;
      height: 70% !important;
    }
  }

  .info {
    max-width: 12.0625rem !important;
    font-size: 0.625rem !important;
    min-width: unset !important;
    margin-left: 1.25rem !important;
    max-height: 7.8125rem !important;
    :nth-child(2) {
      font-size: 1.5rem !important;
      max-width: 13ch !important;
    }

    :nth-child(3) {
      font-size: 0.625rem !important;
    }
  }
  .newsflixWrapper {
    width: 90% !important;
    padding-top: 1rem;
    align-items: flex-start;
    .newsWrapper {
      .dataWrapper {
        margin-top: 2.1875rem !important;
      }
    }
  }
}
