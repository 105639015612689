@import "../../styling//scss-variables.scss";

.footerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  position: relative;
  padding: 5% 5%;
  padding-top: 3%;

  a {
    color: #fff;
  }

  .brandLogo {
    display: flex;
    align-items: center;
    z-index: 2;
    height: 10rem;

    .separater {
      border: 2px solid #fff;
      height: 100%;
      margin: 0 4rem;
    }
    .img1 {
      margin-top: 1.8rem;
    }
    .img2 {
    }
    img {
    }
  }
  .desc {
    display: flex;
    align-items: center;
    color: #fff;
    z-index: 2;
  }
  .privacy {
    z-index: 2;
    margin-top: -4rem;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    background-color: #eb4547;
    z-index: 0;
    filter: blur(15vh);
    -webkit-filter: blur(15vh);
  }

  .socialLinks {
    z-index: 2;
    display: flex;
    align-items: center;

    .socialLink {
      margin-right: 0.5rem;
      padding: 1rem;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .footerWrapper {
    margin-top: 50%;
    .brandLogo {
      display: flex;
      align-items: center;
      z-index: 2;
      height: 8rem;
      width: 100%;

      .separater {
        border: 0.1rem solid #fff;
        height: 100%;
        margin: 0 2.3rem;
      }
      .img1 {
        width: 20rem;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .img2 {
        width: 20rem;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }

    .socialLinks {
      .socialLink {
        margin-right: 0.5rem;
        padding: 0.5rem;
      }
    }
  }
}
