@import "../../../../styling/scss-variables";

.container {
  color: $on-primary2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 21;

  .registeredContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .textThankYou {
      padding-top: 2rem;
    }
    .text1 {
      padding-top: 2rem;
      font-weight: 700;
      font-size: 2rem;
      line-height: 1.5;
    }
  }
  .box {
    background: #0a2040;
    border: 1px solid $primary-color2;
    box-sizing: border-box;
    border-radius: 1.5rem;
    padding: 2.6rem;
    min-width: 35%;
    position: relative;
    transform: translate(0, 0);
    animation: slideUp 0.5s forwards;

    h1 {
      font-size: 2rem;
      margin-bottom: 2.1rem;
    }

    .signin-form {
      display: flex;
      flex-direction: column;

      .input-group {
        > label {
          display: block;
          padding-bottom: 1rem;
        }
      }

      .submit-btn {
      }
    }

    .closeBtn {
      position: absolute;
      top: 5%;
      right: 5%;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .container {
    .registeredContainer {
      .textThankYou {
        width: 90%;
      }
    }
    h1 {
      font-size: 1.7rem !important;
    }
    .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(140%);
  }
  100% {
    transform: translate(0, 0);
  }
}
