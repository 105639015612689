.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  -webkit-animation: fadeIn 0.25s forwards;
  animation: fadeIn 0.25s forwards;
}
.mediaModal {
  position: relative;
  /* width: 75vw; */
  height: 40vw;
  /* opacity: 0; */
  -webkit-animation: scaleUp 0.25s forwards;
  animation: scaleUp 0.25s forwards;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.certificateContainer {
  /* position:fixed;
    width: 50%;
    height: 70vh;
    top: 55%;
    left:50%;
    transform: translate(-50%,-50%);
    z-index: 10;
    font-size: 14px;
    font-weight: 400; */
  position: relative;
  z-index: 2;
  height: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

.certificateContainer .close {
  position: absolute;
  z-index: 20;
  top: 0;
  right: 0;
  cursor: pointer;
  background-color: transparent;
  transform: translate(100%, 0);
}

.certificateContainer > img {
  height: 100%;
  width: auto;
}

.your_name {
  font-style: italic;
  position: absolute;
  bottom: 53.7%;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: calc((75vw * 0.6) / 20);
  color: #3e3e3f;
  font-weight: 600;
}

.certDownload {
  position: absolute;
  transform: scale(0.75);
  bottom: 1.5rem;
  /* bottom: 10%; */
  left: calc(50% - 5rem);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (orientation: landscape) {
  .your_name {
    bottom: 20%;
    font-size: calc((75vw * 0.6) / 20);
  }
}

@media only screen and (orientation: landscape) and (max-width: 990px) {
  .certDownload {
    transform: scale(0.5);
    bottom: 20%;
  }
}

@media only screen and (orientation: portrait) and (max-width: 990px) {
  .mediaModal {
    position: relative;
    aspect-ratio: 75/40;
    /* width: max-content; */
    width: 90vw;
    height: auto;
    /* opacity: 0; */
    -webkit-animation: scaleUp 0.25s forwards;
    animation: scaleUp 0.25s forwards;
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
  }
  .certificateContainer > img {
    width: 100%;
  }
  .certificateContainer .close {
    top: -20%;
    right: 10%;
  }
  .certDownload {
    bottom: 0;
    position: inherit;
    margin: 5%;
    left: calc(50% - 5rem);
  }
  .your_name {
    bottom: 20%;
    font-size: 1.125rem; /*46.7%;*/
  }
}
