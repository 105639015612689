@import "../../../styling/scss-variables.scss";

.raWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  cursor: pointer;

  .cardWrapper,
  .cardWrapperBehind {
    transition: all 0.3s ease-in-out;
    position: absolute;
    // aspect-ratio: 791/586;
    .raCard {
      width: 56.6875rem;
      height: 38.625rem;
      border-radius: 0 50% 50% 0;
      outline: 1px solid #e34143;
      // transform: perspective(10px) rotateX(0.6deg);
      // transition: all 0.3s ease-in-out;
      position: absolute;
      bottom: 0;
      left: -1.875rem;
    }
  }

  .cardWrapper {
    width: 49.4375rem;
    height: 36.625rem;
  }

  .cardWrapperBehind {
    bottom: 0;
    position: absolute;
    left: -100%;
    .raCard {
      width: 1024px;
      min-height: 650px;
      aspect-ratio: 1024/650;
    }
    transition: all 0.3s ease-in-out;
  }
}

.inactiveCardNext {
  transition: all 0.3s ease-in;
  z-index: 0;
  opacity: 0.1;
  width: 18.75rem;
}

.inactiveCardPrev {
  transition: all 0.3s ease-in;
  z-index: 0;
  opacity: 0.1;
  width: 18.75rem;
}

.playBtn {
  left: -1.875rem;
  position: absolute;
  top: -0.625rem;
  width: 9.375rem;
  height: 9.375rem;
}

.activeCard {
  transition: all 0.3s ease-in;
  z-index: 1;
  width: 18.75rem;
}

.dataCard {
  width: 22.928125rem;
  height: 10.480625rem;
  background: #2c4067;
  z-index: 10;
  position: absolute;
  border-radius: 0.625rem;
  bottom: -10%;
  left: -30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.875rem;
  box-sizing: border-box;
  cursor: pointer;

  .btnWrapper {
    position: relative;
    height: 100%;
    // width: 150px;
    width: 40%;
  }

  .dataInfo {
    // margin-left: 40px;
    font-size: 0.75rem;
    width: 60%;
    color: #fff;

    :nth-child(2) {
      font-size: 1.375rem;
      font-weight: bold;
      margin: 0.625rem 0 1.25rem 0;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .raWrapper {
    padding-right: 2.5rem;
    height: 43rem;
    // width: 28rem;
    transform: scale(0.4) !important;
  }
  .inactiveCardNext,
  .inactiveCardPrev,
  .activeCard {
    left: unset !important;
  }
  .dataCard {
    top: 100% !important;
    transform: scale(1.5) !important;
    left: -20%;
  }
  .dataInfo {
    :nth-child(2) {
      font-size: 1.625rem !important;
    }
  }
}
