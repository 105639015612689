@import "../../styling/scss-variables";

.videoPage {
  // overflow-x: hidden;
  background-color: $primary-color;
  position: relative;
  // z-index: 2;
  width: 100%;

  .headerBannerWrapper {
    background-color: #000000;
  }

  .playerSection {
    // padding-top: 4.375rem;
    display: flex;
    // height: 787px;
    height: 80vh;
  }
  .playerSectionFullScreen {
    padding-top: 0 !important;
    height: 100vh !important;
  }

  .contentWrapper {
    padding-top: 8.125rem;
  }

  .loaderWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
  }

  .videoSide {
    flex: 2;
    // background: #424242;
    position: relative;
    // aspect-ratio: 16/9;
    max-width: 100%;
    max-height: 100%;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .overlayData {
      display: block; // hide later
      position: absolute;
      color: $on-primary;
      left: 10%;
      top: 50%;
      width: 90%;
      transform: translate(0, -50%);
    }

    // ADD Later
    // &:hover {
    //   .overlayData {
    //     display: block;
    //     transition: all 1s ease-in;
    //   }
    // }
  }

  .overlayDataMob {
    display: none;
  }

  .notesTranscriptSide {
    position: relative;
    width: 0;
    background: #000;
    .notesOverlay {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.8);
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      overflow: hidden;
    }

    .notesClose {
      position: absolute;
      top: 0;
      right: 0;
      margin: 1.25rem;
      cursor: pointer;
    }
  }
}

.sectionWrapper {
  padding: 9.375rem 0;
  padding-top: 0;
  position: relative;
}
.overlayBG {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}
@media only screen and (max-width: 1366px) {
  .videoPage {
    .playerSection {
      height: 75vh;
    }
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .videoPage {
    .playerSection {
      padding-top: 0;
      // padding-top: 4.625rem;
    }
  }
  .overlayBG {
    display: none;
  }
  .notesTranscriptSideMobile {
    position: fixed !important;
    bottom: 0;
    left: 0;
    right: 0;
    top: 40%;
    z-index: 1;
    width: unset !important;
    // display: none !important;
  }
  .overlayData {
    display: none !important;
  }
  .overlayDataMob {
    display: block !important;
  }
  .contentWrapper {
    padding-top: 0.625rem !important;
  }
  .sectionWrapper {
    padding: 1.25rem 0 !important;
  }
  .playerSection {
    height: auto !important;
  }
  .videoSide {
    &:hover {
      .overlayData {
        display: none !important;
      }
    }
  }
}
