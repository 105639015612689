@import "../../styling/scss-variables";

.container {
  color: $on-primary2;

  .box {
    background: #0a2040;
    border: 1px solid $primary-color2;
    box-sizing: border-box;
    border-radius: 1.5rem;
    padding: 2.6rem;
    min-width: 35%;
    position: relative;

    h1 {
      font-size: 2.5rem;
      margin-bottom: 2.1rem;
    }

    .signin-form {
      display: flex;
      flex-direction: column;

      .input-group {
        > label {
          display: block;
          padding-bottom: 1rem;
        }
      }

      .terms {
        padding-top: 1rem;

        span {
          color: $primary-color2;
        }
      }

      .submit-btn {
      }

      .not-registered {
        margin-top: -1rem;
        margin-bottom: 2rem;

        .not-registered-number {
          color: $primary-color2;
        }
      }
      .not-registered-img {
        margin-bottom: 1rem;
      }
      .notnow-btn {
        border: 1px solid $on-primary2;
        color: $on-primary2;
        background: transparent;
      }

      .formbtns {
        display: flex;
        justify-content: center;
        align-self: normal;
        gap: 2rem;

        button {
          width: 50%;
        }
      }
    }

    .closeBtn {
      position: absolute;
      top: 5%;
      right: 5%;
      cursor: pointer;
    }
  }

  .sinupbtn-container {
    font-size: 1.4rem;
    font-weight: 300;

    span {
      cursor: pointer;
      color: $primary-color2;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .container {
    .box {
      height: 100%;
      width: 100%;
      border-radius: 0;
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .sinupbtn-container {
      // position: absolute;
      font-size: 1.3rem;
      // bottom: 2%;
    }
  }
}
