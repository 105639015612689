html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

body {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--primary-color);
}

a {
  color: inherit;
  text-decoration: none;
}

html {
  /* scroll-behavior: smooth; */
}

.w-100 {
  width: 100%;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}
.d-block {
  display: block;
}

.text-align-center {
  text-align: center;
}

.mobile {
  display: none;
}

.desktop {
  display: flex;
}

.overlay > * {
  width: 60px;
  height: 60px;
  margin: 0 20px;
}

*[slot="centered-chrome"] {
  margin: 0 15%;
  --media-control-hover-background: none;
  --media-control-background: none;
}

.widget-svg {
  stroke: #fff;
  stroke-width: 1.5;
}

.widget-svg:hover {
  stroke: none;
}

.form-control {
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  height: auto;
  width: 100%;
  color: #fff;
  background: transparent;
  margin-top: 0.8rem;
}
.PhoneInputInput{
  background: transparent;
  color: #fff;
  border: none;
}

.form-control:focus ,.PhoneInputInput:focus {
  outline: none;
}

/* custom checkbox  start*/
.custom-checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}
input:disabled {
  background-color: #ccc;
  color: #000;
  cursor: not-allowed;
}
.custom-checkbox > input[type="checkbox"] {
  height: 25px;
  width: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #c2cedb;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: transparent;
  cursor: pointer;
}

.custom-checkbox > input[type="checkbox"]:checked {
  border: 1px solid #eb4547;
  background-color: #eb4547;
}

.custom-checkbox > input[type="checkbox"]:checked::before {
  content: "\2713";
  /*  content: "✔"; */
  display: block;
  color: #fff;
  position: absolute;
  font-size: 1rem;
  font-weight: 700;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* custom checkbox end */

/* custom radio button start */
.custom-radiobtn {
  display: block;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-radiobtn > input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.custom-radiobtn-checked {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.2rem;
  width: 1.2rem;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.custom-radiobtn:hover > input[type="radio"] ~ .custom-radiobtn-checked {
  background-color: #ccc;
}

/* When the radio button is checked, add a background */
.custom-radiobtn
  > input[type="radio"]:checked[type="radio"]
  ~ .custom-radiobtn-checked {
  background-color: #eb4547;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-radiobtn-checked:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radiobtn
  > input[type="radio"]:checked[type="radio"]
  ~ .custom-radiobtn-checked:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radiobtn .custom-radiobtn-checked:after {
  top: 0.35rem;
  left: 0.35rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #fff;
}

/* custom radio button end */

.btn {
  margin-top: 2.2rem;
  border-radius: 7px;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1rem;
  background-color: #eb4547;
  color: #fff;
  display: flex;
  height: 4rem;
  align-items: center;
  justify-content: center;
}

.btn:hover:not([disabled]) {
  background-color: #fff;
  color: #eb4547;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

button:disabled {
  background-color: #808080;
  cursor: not-allowed;
}

/* hide updown arrow of type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.modalOverlay,
.modalOverlayWithTint {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
}

.modalOverlayWithTint {
  background: rgba(0, 0, 0, 0.4);
}

.modalOverlayWithBlur {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background: rgba(27, 50, 87, 0.6);
  backdrop-filter: blur(32px);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.liveText {
  font-size: 1rem;
}

.liveDot {
  display: inline-block;
  height: 0.5rem;
  border-radius: 50%;
  width: 0.5rem;
  background-color: #eb4547;
}

/* toast container class */
.toast-container {
  background-color: #eb4547;
  color: #fff;
}

.Toastify__toast-icon > svg {
  --toastify-icon-color-success: #fff;
}

.Toastify__close-button {
  color: #fff;
  opacity: 0.7;
}

/* interaction in webinar */
.clickedReaction {
  background-color: #fff;
  color: #eb4547 !important;
}

/* error */
.error {
  color: #eb4547;
  padding-top: 1rem;
}

/* fixed invite modal in center */
.inviteModal {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* video loader start */
.videoLoader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
}

.videoLoader > img {
  max-height: 50px;
  max-width: 50px;
}

/* video loader end */

@media screen and (max-width: 1680px) {
  html,
  body {
    font-size: 80%;
  }
}

/* @media screen and (max-width: 1366px) {
  html,
  body {
    font-size: 80%;
  }
} */
@media screen and (max-width: 768px) {
  [data-aos] {
    pointer-events: auto !important;
  }

  html:not(.no-js) [data-aos^="fade"][data-aos^="fade"] {
    opacity: 1 !important;
  }

  html:not(.no-js) [data-aos="fade-up"] {
    transform: none !important;
  }

  media-control-bar.desktop {
    /* display: inline-flex; */
  }
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  *[slot="centered-chrome"] {
    margin: 0;
  }

  .overlay > * {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 600px) {
  html,
  body {
    font-size: 14px;
  }

  .mobile {
    display: flex;
  }

  .desktop {
    display: none;
  }
}
