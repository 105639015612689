@import "../../styling/scss-variables";

p,
h1 {
  margin: 0;
}

.container {
  color: $on-primary2;

  .box {
    display: flex;
    position: relative;
    padding: 1rem;

    .left {
      background: rgba(44, 64, 103, 0.5);
      backdrop-filter: blur(23px);
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      padding: 3rem 5rem;

      h1 {
        margin-bottom: 1.8rem;
      }

      .progress {
        margin-top: 1rem;
        margin-bottom: 3rem;

        .step {
          position: relative;
          height: 1.5rem;
          width: 11rem;
          top: 0;

          .stepName {
            position: absolute;
            bottom: 150%;
            // margin-left: 8rem;
          }
          .stepNameVerified {
            color: $primary-color2;
            font-weight: 900;
          }
          .stepImg {
            position: absolute;
            top: -50%;
          }
        }
      }

      .profileContainer {
        padding: 0 3rem;

        .signup-form {
          display: flex;
          flex-direction: column;

          .input-group {
            margin-bottom: 1rem;
            > label {
              display: block;
              padding-bottom: 1rem;
            }
          }

          .names {
            display: flex;
            align-items: flex-end;
            gap: 2rem;
          }
          .next-btn {
          }
        }
      }

    }

    .right {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .closeBtn {
      display: flex;
      position: absolute;
      background: #1d345a;
      border-radius: 50%;
      padding: 0.5rem;
      top: 5%;
      right: 3%;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1366px) {
  .container {
    .box {
      width: 100%;
      transform: scale(0.8);

      .left {
        padding: 3rem 2rem;

        .profileContainer {
          padding: 0 1rem;
        }
      }
      .right {
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  .container {
    .box {
      transform: scale(1);
      height: 100%;
      padding: 0;
      flex-direction: column-reverse;
      flex-grow: 1;

      .left {
        padding: 3rem 2rem;
        border-radius: 0;
        height: 100%;
        overflow: auto;

        .profileContainer {
          padding: 0 1rem;

          .signup-form {
            .input-group {
              width: 100%;
            }
          }
        }
      }

      .right {
        display: none;
        height: 50%;
        img {
          height: unset;
          width: 25%;
        }
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .container {
    .box {
      .right {
        img {
          width: 35%;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .container {
    .box {
      .left {
        .profileContainer {
          padding: 0;
          .signup-form {
            .names {
              gap: 1rem;
            }
          }
        }
      }
      .right {
        img {
          height: 100%;
          width: 100%;
        }
      }

      .closeBtn {
        top: 2%;
        right: 2%;
      }
    }
  }
}
