@import "../../styling/scss-variables.scss";

.sliderControls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.875rem;
  margin-bottom: 1.5625rem;
}

.arrowLeft {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid $disabled-arrow;
  border-right: none;
  border-bottom: none;
  transform: rotate(-45deg);
  position: relative;
  margin-right: calc(18px + 3.375rem);
  &::after {
    content: "";
    position: absolute;
    width: 3.375rem;
    height: 2px;
    background: $disabled-arrow;
    transform: rotate(45deg) translate3d(5px, 18px, 0);
  }
}

.arrowLeftActive {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid $on-primary;
  border-right: none;
  border-bottom: none;
  transform: rotate(-45deg);
  position: relative;
  margin-right: calc(1.125rem + 3.375rem);
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    width: 3.375rem;
    height: 0.125rem;
    background: $on-primary;
    transform: rotate(45deg) translate3d(0.3125rem, 1.125rem, 0);
  }
}

.arrowRight {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid $disabled-arrow;
  border-left: none;
  border-bottom: none;
  transform: rotate(45deg);
  position: relative;
  margin-left: calc(1.125rem + 3.375rem);
  &::after {
    content: "";
    position: absolute;
    width: 3.375rem;
    height: 0.125rem;
    background: $disabled-arrow;
    transform: rotate(-45deg) translate3d(-1.875rem, -0.375rem, 0);
  }
}

.arrowRightActive {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid $on-primary;
  border-left: none;
  border-bottom: none;
  transform: rotate(45deg);
  position: relative;
  margin-left: calc(1.125rem + 3.375rem);
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    width: 3.375rem;
    height: 0.125rem;
    background: $on-primary;
    transform: rotate(-45deg) translate3d(-1.875rem, -0.375rem, 0);
  }
}
