@import "../../styling/scss-variables";

.container {
  background: #101726;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 24px;
  position: absolute;
  width: 35rem;
  max-width: 35rem;
  padding: 3rem 2rem;
  z-index: 21;
  right: 5%;
  color: $on-primary2;
  animation: fadeIn 0.2s ease-in-out;

  .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .heading {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .closeBtn {
      cursor: pointer;
      position: absolute;
      right: 1%;
      margin-bottom: 0.5rem;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .inputContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #8492a6;
      box-sizing: border-box;
      border-radius: 0.25rem;
      height: 4rem;
      padding: 1rem 0.5rem;
      gap: 1rem;
      width: 100%;

      input {
        outline: none;
        border: none;
        width: 100%;
        height: 100%;
        color: $on-primary2;
        background-color: transparent;
      }

      .copyBtnContainer {
        position: relative;

        .copyBtn {
          cursor: pointer;
        }

        .tooltiptext {
          width: 7.5rem;
          background-color: #000;
          color: #fff;
          font-size: 0.9rem;
          text-align: center;
          padding: 0.3125rem 0;
          border-radius: 0.375rem;
          display: none;

          /* Position the tooltip text - see examples below! */
          position: absolute;
          bottom: 100%;
          left: -100%;
          z-index: 1;

          &::after {
            content: " ";
            position: absolute;
            top: 100%; /* At the bottom of the tooltip */
            left: 50%;
            transform: translateX(-50%);
            border-width: 0.3125rem;
            border-style: solid;
            border-color: #000 transparent transparent transparent;
          }
        }
        &:hover > .tooltiptext {
          display: block;
        }
      }
    }

    .shareContainer {
      display: flex;
      width: 100%;
      font-size: 1.2rem;
      padding: 1.1rem 0rem;
      justify-content: space-between;

      .shareIcons {
        display: flex;
        align-items: center;
        gap: 1.5rem;

        .icon {
          cursor: pointer;
        }
      }
    }

    .break {
      position: relative;
      color: #8492a6;

      &::before,
      &::after {
        display: block;
        height: 1px;
        background: #c2cedb;
        position: absolute;
        width: 13.3rem;
        top: 50%;
      }

      &::before {
        content: "";
        left: 2.8125rem;
      }

      &::after {
        content: "";
        right: 2.8125rem;
      }
    }

    .emailContainer {
      width: 100%;
      font-size: 1.2rem;
      margin-top: 1rem;

      .inputContainer {
        margin-top: 1rem;

        .sendBtn {
          height: 3rem;
          min-width: 5rem;
          margin: unset;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media screen and (max-width: $mobile-breakpoint) {
  .container {
    width: 96%;
    right: 0;
    .header {
      margin-bottom: 1rem;
      .heading {
        font-size: 1.2rem;
        font-weight: 600;
      }
    }
    .body {
      .shareContainer {
        font-size: 1rem;
      }
      .emailContainer {
        font-size: 1rem;
      }
      .break {
        position: relative;
        color: #8492a6;

        &::before,
        &::after {
          width: 10.6rem;
        }
      }
    }
  }
}
