@import "../../styling/scss-variables.scss";

.container {
  // padding: 0 10rem;
  .topicsWrapper {
    display: flex;
    overflow-x: auto;
    // padding: 0 30vw 0 0px;
    padding-right: 50vw;
    // overflow-y: hidden;
    padding: 3.125rem 6%;
  }
  .topicsData {
    height: 31.625rem;
    background-size: cover !important;
    border-radius: 1.5rem;
    margin-right: 0.6875rem;
    color: $secondary-color2;
    padding: 1.625rem 2.6875rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 120%;
    transition: min-width 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
    cursor: pointer;

    &:hover {
      transform: scale(1.05) !important;
      transition: all 0.2s;
    }

    .viewsAndDuration {
      display: flex;
      font-size: 1.25rem;
      font-weight: 100;
      margin-top: 0.9375rem;
      text-transform: capitalize;
      letter-spacing: -0.02em;

      > div:first-child {
        margin-right: 1.5rem;
      }
    }

    .contentDataHidden {
      overflow: hidden; /* Hide the element content, while height = 0 */
      height: 0;
      opacity: 0;
      transition: height 0ms 2s, opacity 2s 0ms;
    }

    .contentData {
      height: auto;
      opacity: 1;
      transition: height 0ms 0ms, opacity 2s 0ms;
      > img {
        margin-bottom: 0.625rem;
        transform: translate(-2.5rem, 1.25rem) scale(0.8);
        cursor: pointer;
        // &:hover {
        //   transform: scale(1.05);
        //   transition: all 0.2s ease-in-out;
        // }
      }
    }
  }
}
.tag {
  text-transform: capitalize;
  font-size: 1rem;
}
.comingSoon {
  font-size: 0.75rem;
  font-weight: 400;
}
@media only screen and (max-width: $mobile-breakpoint) {
  .topicsWrapper {
    padding: 0.625rem !important;
    margin-left: 0.9375rem;
  }
  .topicsData {
    height: 19.875rem !important;
    font-size: 1rem !important;
    padding: 0.875rem !important;
    margin-right: 1.5625rem !important;

    &:first-child {
      margin-left: 0px !important;
    }

    .contentData {
      > img {
        height: 8rem;
      }
    }
  }
  .viewsAndDuration {
    font-size: 0.625rem !important;
  }
}
