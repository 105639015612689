@import "../../../styling/scss-variables";


.searchWrapper {
    position: fixed;
    left: 0;
    right:  0;
    top: 0;
    bottom: 0;
    backdrop-filter: blur(24px);
    background-color: transparent;
    z-index: 14;
    animation: zoomIn 0.5s ease-in-out;
    .searchForm {
        display: flex;
        align-items: center;
        padding: 0 2rem;
        height: 4rem;
        gap: 2rem;
        margin-top: 2rem;
        .inputWrapper {
            background-color: #000; 
            width: 90%;
            padding: 0 0.625rem;
            display: flex;
            align-items: center;
            border-radius: 50px;
            gap: 5px;
            .input  {
                color:#fff;
                width: 95%;
                padding: 1.25rem 0.9rem;
                border: none;
                border-radius: 50px;
                background-color: #000;
                &:focus {
                    outline: none;
                }
            }
          }
          .close {
              width: 3rem;
              height: 3rem;
          }
    }
    .showsWrapper {
        height: calc(100% - 6rem);
        overflow-x: hidden;
        overflow-y: scroll;
        
        .showsTitle {
            color: #fff;
            padding: 2rem;
            font-size: 2rem;
            font-weight: 700;
        }

        .showsContainer {
            width: 100%;
            display: flex;
            gap: 1rem;
            padding: 0 2rem;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            cursor: pointer;
           
            .ImageContainer {
                position: relative;
                width: 10rem;
                aspect-ratio: 4/3;
                .title {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    color: #fff;
                    text-align: center;
                }
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }

            }
           
          
        } 
        
       
    }
    
   
}

@media only screen and (max-width: $mobile-breakpoint) {
    .searchWrapper {
        .searchForm {
            .inputWrapper {
               .input {
                   width: 85%;
               } 
            }
        }
        .showsWrapper {
            .showsTitle {
                color: #fff;
                padding: 2rem 1.5rem;
                font-size: 1rem;
            }
            .showsContainer {
                padding: 0 5%;
                justify-content: flex-start;
                .ImageContainer {
                    width: 27%;   
                }               
              
            } 
           
           
        }
    }
  }
  