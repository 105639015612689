@import "../../../../styling/scss-variables";

.headCard {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 150px;
  .invite {
    position: absolute;
    right: 15%;
    top: 120%;
  }
  .mainHead {
    position: relative;
    .thumbImg {
      width: 80vw;
      min-height: 375px;
      max-height: 375px;
      object-fit: cover;
      display: flex;
      border-radius: 10px;
    }
    .data {
      position: absolute;
      width: 35%;
      height: 100%;
      top: 35%;
      margin: 0 10%;
      color: #fff;
      .title {
        font-weight: bold;
        font-size: 1.8rem;
        margin-bottom: 35px;
      }
    }
  }
  .timerCard {
    position: absolute;
    top: 80%;
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    backdrop-filter: blur(20px);
    border-radius: 24px;
    width: 70%;
    padding: 30px;
    box-sizing: border-box;
    color: $on-primary;
    font-weight: 400;
    font-size: 1.1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .smallInfo {
      font-weight: normal;
      font-size: 0.8rem;
      margin-top: 4px;
    }
    .timer,
    .count {
      margin-top: 10px;
      font-weight: 600;
      font-size: 1.6rem;
    }

    .timer {
      display: flex;
      max-width: 300px;

      .timeValue {
        margin-right: 15px;
      }
    }

    .sharePart {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      cursor: pointer;

      img {
        margin-right: 10px;
        width: 40px;
        // margin-left: 40px;
      }
    }

    .timerPart,
    .attendiesPart,
    .bookPart {
      margin-right: 25px;
    }

    .timerPart {
      .live {
        font-size: 1.4rem;
        font-weight: 600;
      }
      .eventLive {
        font-size: 2.325rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 0.3125rem;
      }
    }

    .bookPart {
      .bookBtn {
        background: #eb4547;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 1rem;
        height: 4rem;
        font-size: 1.2rem;
        color: #fff;
        font-weight: bold;
      }
      img {
        max-height: 60%;
      }
    }
    .actionBtns {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }
  }
  .timerCard2 {
    top: 89%;
    justify-content: center;
  }
}

@media only screen and (min-width: $mobile-breakpoint) and (max-width: 1240px) {
  .smallInfo {
    font-size: 0.6rem;
  }
  .timeValue {
    margin-right: 1.2rem;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .headCard {
    margin-bottom: 270px;
    // .invite {
    //   position: relative;
    //   margin-bottom: -80%;
    // }
  }
  .title {
    font-size: 24px !important;
  }
  .thumbImg {
    width: 100vw !important;
  }
  .data {
    width: 90% !important;
  }
  .timerCard {
    flex-direction: column !important;
    font-size: 1rem !important;
    height: 85% !important;
    width: 80% !important;
    padding: 1rem 1.6875rem !important;
    text-align: center;
    top: 70% !important;
    justify-content: space-between !important;
    .timer,
    .count {
      font-size: 2rem !important;
      width: 100% !important;
    }
    .timeValue {
      margin: 0 0.625rem !important;
    }
  }
  .timerCard2 {
    justify-content: center !important;
  }
  .actionBtns {
    .bookBtn {
      // margin-right: 20px !important;
      font-size: 14px !important;
      //   padding: 12px 35px !important;
      max-width: 200px !important;
      min-width: 200px !important;
      height: 42px !important;
    }
    .sharePart {
      span {
        display: none !important;
      }
      img {
        width: 24px !important;
      }
    }
  }
  .timerPart,
  .attendiesPart,
  .bookPart {
    margin-right: 0px !important;
  }
}
