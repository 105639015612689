@import "../../styling/scss-variables.scss";

.animatedHeader {
  display: flex;
  position: relative;
  padding: 0 6%;
  overflow-x: hidden;
  align-items: center;

  .mainHeader {
    font-weight: bold;
    font-size: 3.125rem;
    z-index: 2;
    color: $on-primary;
    // margin-left: 180px;
  }

  // .bgHeader {
  //   font-weight: bold;
  //   font-size: 200px;
  //   color: transparent;
  //   position: absolute;
  //   -webkit-font-smoothing: antialiased;
  //   -webkit-text-stroke: 1px #1ab399;
  //   white-space: nowrap;
  //   -webkit-backface-visibility: hidden;
  //   backface-visibility: hidden;
  //   transition: all 0.1s;
  // }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .mainHeader {
    font-size: 1.5rem !important;
  }
  .bgHeader {
    font-size: 4rem !important;
    img {
      width: 100%;
    }
  }
}
