@import "./css-variables.scss";

// Spacings
$space-xs: 4px;
$space-s: 8px;
$space-m: 16px;
$space-l: 24px;
$space-xl: 32px;

// Breakpoints
$desktop-breakpoint: 1440px;
$tablet-breakpoint: 800px;
$mobile-breakpoint: 600px;

// css varibles
$primary-color: var(--primary-color);
$primary-color2: var(--primary-color2);
$secondary-color: var(--secondary-color);
$secondary-color2: var(--secondary-color2);
$surface: var(--surface);
$error: var(--error);
$on-primary: var(--on-primary);
$on-primary2: var(--on-primary2);
$grey1: var(--grey1);
$banner-tile: var(--banner-tile);
$disabled-arrow: var(--disabled-arrow);
$shimmer-bg: var(--shimmer-bg);
$shimmer-color2: var(--shimmer-color2);
$shimmer-color3: var(--shimmer-color3);
$shimmer-color4: var(--shimmer-color4);
$tooltip-color: var(--tooltip-color);
$logout-color: var(--logout-color);
