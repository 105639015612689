@import "../../styling/scss-variables.scss";

.plusIconLight {
  position: absolute;
  left: 5%;
  // max-width: 250px;

  .overlayImg {
    width: 50%;
    opacity: 0.5;
  }
}

.recentWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  position: relative;
  padding-top: 0.625rem;
  padding-bottom: 4.375rem;
  transform: scale(0.8);
}

.controls {
  position: absolute;
  top: 50%;
  width: 100%;
  justify-content: space-between !important;
  padding: 5% 20%;
  box-sizing: border-box;
}

@media only screen and (max-width: $mobile-breakpoint) {
  .recentWrapper {
    max-width: $mobile-breakpoint;
    display: flex;
    overflow: hidden;
    padding: 0 !important;
    justify-content: flex-start !important;
    align-items: center;
    // height: $mobile-breakpoint;
    height: 30rem;
    margin: -7rem -1rem -2.5rem -1rem !important;
  }
  .controls {
    transform: scale(0.8);
    padding: 5% 0% !important;
  }
  .controls {
    transform: scale(0.8);
    padding: 5% 0% !important;
  }
}
