@import "../../styling//scss-variables.scss";

.contactFooter {
  margin-top: 6.25rem;
  font-size: 1.125rem;
  color: $on-primary;
  position: relative;

  .brandName {
    display: flex;
    align-items: center;
    font-size: 1.625rem;
    font-weight: bold;
    > img {
      width: 2.125rem;
      height: 2.3125rem;
      margin-right: 0.75rem;
    }
  }

  .parallaxItem {
    .plusIconDark {
      position: absolute;
      right: 0%;
      top: 50%;
      .overlayImg {
        width: 50%;
        opacity: 0.5;
      }
    }
  }

  // &::before {
  //   content: "";
  //   width: 100vw;
  //   // height: 647px;
  //   height: 100%;
  //   background-color: $primary-color2;
  //   position: absolute;
  //   z-index: -1;
  //   filter: blur(15vh);
  //   -webkit-filter: blur(15vh);
  // }

  .wrapper {
    position: relative;
    width: 80%;
    margin: 0 auto;
  }

  .getInTouch {
    font-size: 1.125rem;
    width: 20.125rem;
    line-height: 1.5625rem;
    :first-child {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 134%;
      margin-bottom: 0.625rem;
    }
    a {
      color: $on-primary;
      font-size: 1.125rem !important;
      font-weight: normal !important;
    }
  }
  .footerBottom {
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 7.375rem;

    .infoSide {
      width: 17.0625rem;
      margin-bottom: 1.75rem;
      .info {
        margin-top: 1.25rem;
        font-size: 1.125rem;
        line-height: 141%;
        font-weight: normal;
        margin-bottom: 1.5rem;
      }
      .socialLink {
        margin-right: 2rem;
        font-weight: 400;
      }
    }
  }
}

.contactFooter_zIndexZero {
  &::before {
    content: "";
    z-index: 0;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .footerBottom {
    flex-direction: column;
  }

  .plusIconDark {
    right: 10% !important;
    position: absolute;
    top: -15%;
    img {
      width: 9.0625rem;
    }
  }
  .contactFooter {
    .footerWrapper {
      margin-top: 15%;
      .brandLogo {
        display: flex;
        align-items: center;
        z-index: 2;
        height: 8rem;
        width: 100%;

        .separater {
          border: 0.1rem solid #fff;
          height: 100%;
          margin: 0 2.3rem;
        }
        .img1 {
          width: 20rem;
          img {
            height: 100%;
            width: 100%;
          }
        }
        .img2 {
          width: 20rem;
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
