@import "../../../styling/scss-variables.scss";

.borderSpeaker {
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: absolute;
  bottom: 0;
  z-index: 21;
  box-sizing: border-box;
  border-radius: 12px;
  width: 37.2rem;
  margin-left: 2rem;
  animation: fadeIn 0.4s ease-in-out;

  .AllSpeakerPopUpTag {
    box-sizing: border-box;
    background: #101726;
    // border-top: 2rem solid transparent;
    // border-bottom: 2rem solid transparent;
    border-radius: 12px;
    padding: 2rem 1rem;
    max-height: 28rem;
    overflow: auto;

    &::-webkit-scrollbar {
      display: block !important;
    }
    .AllSpeakerWrapper {
      color: $on-primary;
      text-align: center;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 3rem;

      .AllSpeakerCard {
        cursor: pointer;
        .name {
          margin: 0.3rem 0;
          font-size: 0.85rem;
          font-weight: 600;
        }
        .speciality {
          font-size: 0.7rem;
          font-weight: 400;
        }
        img {
          width: 5.3125rem;
          height: 5.3125rem;
          border: 1px solid $on-primary;
          border-radius: 50%;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .borderSpeaker {
    width: 98%;
    position: fixed;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
    max-height: 24.8rem;

    .AllSpeakerPopUpTag {
      max-height: 100%;

      .AllSpeakerWrapper {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
