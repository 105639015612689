.container {
  position: absolute;
  bottom: 85%;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  height: 20rem;
  width: 20rem;
  pointer-events: none;

  svg {
    transform: translate3d(0px, 0px, 0px) translateX(-41%) !important;
  }
}
.scaleUp {
  // transform: scale(1.2);
  opacity: 1;
}
