@import "../../styling/scss-variables";

.videosSection {
  padding: 6%;
  padding-top: 0;
  padding-bottom: 0.5%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .rowData {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.125rem;
    color: $on-primary;
    margin-top: 0.75rem;

    > a,
    .viewlink {
      color: #eb4547;
      font-weight: bold;
      font-size: 1.25rem;
      text-decoration: none;
      cursor: pointer;
      user-select: none;
    }
  }

  .title {
    font-weight: bold;
    font-size: 2rem;
    // color: $on-primary;
    color: #eb4547;
  }

  .wrapped {
    flex-wrap: wrap;
  }
  .videosWrapper {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 0.625rem;
    // margin-top: 33px;
    display: flex;

    .videoThumb {
      width: 100%;
      display: flex;
      border-radius: 8px 50px 0 0;
    }

    .playBtn {
      position: absolute;
      z-index: 1;
      top: unset !important;
      right: unset !important;
      left: 0 !important;
      bottom: 0;
      width: 25% !important;

      height: unset !important;
      min-width: unset !important;
      min-height: unset !important;
    }
    .videoType {
      position: absolute;
      z-index: 1;
      right: 1%;
      bottom: 1%;
      width: 2rem;
      object-fit: contain;
      height: 2rem !important;
    }

    .card {
      width: calc(25% - 1.5rem);
      margin-right: 0.625rem;
      // margin-right: 24px;
      box-sizing: border-box;
      position: relative;
    }

    .videoCard {
      cursor: pointer;
      position: relative;
      padding: 0.625rem;

      &:hover {
        transform: scale(1.05);
        transition: all 0.5s ease;
      }

      .countCard {
        font-weight: 600;
        font-size: 2rem;
        color: #fff;
        position: absolute;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        top: 0;
        border-radius: 8px;
        z-index: 2;
        text-decoration: none;
      }

      .durationBar {
        background: #393939;
        height: 0.325rem;
        width: 100%;
        border-radius: 0 0 4px 4px;
        position: relative;

        .duration {
          background-color: "#EB4547";
          position: "absolute";
          height: 0.375rem;
          border-radius: 4px;
          z-index: 1;
        }
      }
    }
  }
}

.OTPContainer {
  color: $on-primary2;
  background-color: #2c4067 !important;

  .box {
    background: #0a2040;
    border: 1px solid $primary-color2;
    box-sizing: border-box;
    border-radius: 1.5rem;
    padding: 2.6rem;
    min-width: 35%;
    position: relative;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .videosSection {
    padding-right: 0;
  }
  .card {
    min-width: 80% !important;
  }
  .rowData {
    margin-right: 0 !important;
  }
  .title {
    font-size: 1.125rem !important;
  }
  .viewlink {
    display: none !important;
  }
  .wrapper {
    height: 8.4375rem !important;
  }
  .videosWrapper {
    flex-wrap: none !important;
  }
  .wrapped {
    flex-wrap: unset !important;
  }
}
