@import "../../styling/scss-variables";

.tabsWrapper {
  height: 100%;
  overflow: hidden;

  .headerTabs {
    display: flex;
    margin-bottom: 4rem;
    // overflow-y: auto;
    padding: 0 10%;

    .closeBtn {
      position: absolute;
      right: 3%;
      top: 27%;
      align-self: center;
      cursor: pointer;
    }

    .tab {
      font-weight: bold;
      font-size: 1.5rem;
      // margin-right: 5.625rem;
      margin-right: 10%;
      color: $on-primary;
      cursor: pointer;
    }

    .activeTab {
      color: $on-primary;
      border-bottom: 2px solid $on-primary;
      padding-bottom: 0.625rem;
    }
  }
  .tabContent {
    padding: 0 10%;
    height: calc(100% - 4.4375rem - 3.25rem);
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .headerTabs {
    gap: 1rem;
    // justify-content: space-between;
    margin-bottom: 0 !important;
    padding: 1.25rem !important;
    padding-bottom: 1px !important;
    border-bottom: 1px solid $on-primary;
    .tab {
      font-size: 0.95rem !important;
      margin-right: 0 !important;
    }
    .activeTab {
      border-bottom: 2px solid #eb4547 !important;
    }
  }
  .tabContent {
    padding: 1.25rem !important;
    height: calc(100% - 12.5rem);
  }
}

@media only screen and (max-width: 1260px) and (min-width: $mobile-breakpoint) {
  .tab {
    font-size: 1.4rem !important;
  }
}
