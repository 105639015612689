@import "../../../../styling/scss-variables";

.sessionOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
}

.sessionContainer {
    position: absolute;
    z-index: 8;
    width: 35%;
    margin-top: 10px;
    padding: 1.5rem;
    height: auto;
    background-color: rgba(0, 0, 0, 0.4);
    border: 1px solid grey;
    border-radius: 1.75rem;
    backdrop-filter: blur(24px);
    .title {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }
    .subheader {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.6;
        .subdetails {
            font-weight: 200;
            color: #ffffff;
        }
    }
    .speakerList {
        margin-top: 20px;
        font-size: 1rem;
        font-weight: 400;
        .speaker {
            display: flex;
            margin-top: 10px;
            .details {
                color: #eb4547;
            }
        }
        img {
            border-radius: 50%;
            width: 4.375rem;
            margin-right: 10px;
        }
    }
    .name {
        font-size: 1.25rem;
        line-height: normal;
    }

}

@media only screen and (max-width: $mobile-breakpoint) { 

    .sessionContainer {
        width: 100%;
        box-sizing: border-box;
        left: 0;
        border-radius: 0.75rem;
        .title {
            font-size: 15px;
        }
        .subheader {
            font-size: 12px;
        }
        .speakerList {
            font-size: 12px;
        }
        .name {
            font-size: 15px;
            line-height: 1.9;
        }
    }
}