@import "../../styling/scss-variables.scss";

.mapperContainer {
  font-weight: bold;
  font-size: 1rem;
  line-height: 108%;
  .linksWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    -moz-transition: minHeight 0.2s;
    -ms-transition: minHeight 0.2s;
    -o-transition: minHeight 0.2s;
    -webkit-transition: minHeight 0.2s;
    transition: minHeight 0.2s;
    a {
      color: $on-primary;
      text-decoration: none;
      font-weight: normal;
      font-size: 0.9375rem;
      line-height: 200%;
      background-image: linear-gradient(
        transparent 0%,
        transparent 94%,
        #fff 94%,
        #fff 100%
      );
      display: inline-block;
      background-repeat: no-repeat;
      background-size: 0% 100%;
      background-position-x: right;
      transition: background-size 300ms;

      &:hover {
        // text-decoration: underline;
        background-size: 100% 100%;
        background-position-x: left;
      }
    }
  }
}

.toggleWrapper {
  padding: 1rem 0;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.arrow {
  width: 0.4375rem;
  height: 0.4375rem;
  border: 2px solid $on-primary;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotate(45deg);
}

@media only screen and (max-width: $mobile-breakpoint) {
  .toggleWrapper {
    width: 100%;
    border-top: 1px solid $on-primary;

    &:last-of-type {
      border-bottom: 1px solid $on-primary;
    }
  }
}
