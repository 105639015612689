@import "../../../../styling/scss-variables";

.timelineWrapper {
  color: $on-primary;

  .row {
    display: flex;
  }
  .column {
    display: flex;
    flex-direction: column;
  }
  .eventwrapper {
    margin: 1.25rem 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      border-left: 0.125rem solid $on-primary;
      height: calc(100% + 24px);
      left: calc(13px / 2);
      top: 3rem;
    }

    &:last-child::before {
      display: none;
    }

    .leftSection {
      flex: 1;
      padding: 2rem 0;

      .dateNdot {
        display: flex;
        align-items: center;
        font-size: 1.3rem;
      }
      .eventDotPast {
        width: 1rem;
        height: 1rem;
        background: #eb4547;
        border-radius: 50%;
        border: 0.125rem solid #eb4547;
        outline-offset: 3px;
        position: relative;
        margin-right: 1.0625rem;
      }
      .eventDot {
        width: 1rem;
        height: 1rem;
        background: $on-primary;
        border-radius: 50%;
        // outline: 0.125rem solid $on-primary;
        // outline-offset: 3px;
        position: relative;
        margin-right: 0.7rem;

        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 150%;
          height: 150%;
          border-radius: 50%;
          border: 0.125rem solid $on-primary;
        }
      }

      .eventDotActive {
        width: 1rem;
        height: 1rem;
        background: #eb4547;
        border-radius: 50%;
        margin-right: 1.0625rem;
        position: relative;
        transform: scale(1.5);

        &::after {
          content: "";
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 1.5px solid #eb4547;
          animation: glow-grow 2s ease-out infinite;
          box-sizing: border-box;
          position: absolute;
        }
      }

      @keyframes glow-grow {
        0% {
          opacity: 1;
          transform: scale(1);
        }
        80% {
          opacity: 1;
        }
        100% {
          transform: scale(2);
          opacity: 1;
        }
      }
    }

    .rightCard {
      flex: 4;
      padding: 2rem;
      font-weight: bold;
      font-size: 1.4rem;
      overflow: hidden;
      max-width: 100vw;

      .title {
        margin-bottom: 1.75rem;
      }

      .speakers {
        font-size: 1.1rem;
        font-weight: 600;
        color: #9e9e9e;

        .speakersRow {
          color: $on-primary;
          font-size: 1.2rem;
          flex-wrap: wrap;
          gap: 1rem;

          .speaker {
            width: 33%;
            margin-top: 1rem;
          }

          .speakerImg {
            margin-right: 1rem;
            min-width: 5rem;
            max-width: 5rem;
            min-height: 5rem;
            max-height: 5rem;
            border-radius: 50%;
            background: #0a2347;
            // border: 0.125rem solid #eb4547;
            overflow: hidden;

            img {
              max-width: 100%;
            }
          }
          .speakerInfo {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 85%;
            .info {
              font-size: 0.95rem;
              // line-height: 1.1025rem;
              font-weight: 400;
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
    .rightCardActive {
      background: rgba(0, 0, 0, 0.4);
      border: 1px solid #eb4547;
      box-sizing: border-box;
      backdrop-filter: blur(10px);
      border-radius: 1.75rem;

      .title {
        color: #eb4547;
      }
    }
  }
  .eventwrapperPast {
    opacity: 0.5;
    &::before {
      content: "";
      position: absolute;
      border-left: 0.125rem solid #eb4547;
      height: calc(100% + 24px);
      left: calc(13px / 2);
    }

    &:last-child::before {
      display: none;
    }
  }
  .activeActions {
    font-size: 0.9rem;
    display: flex;
    margin-top: 30px;
    align-items: center;

    .likeBtn {
      display: flex;
      align-items: center;
      margin-right: 3.125rem;
      img {
        margin-right: 0.75rem;
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .rightCard {
    margin-left: 2rem !important;
    padding: 0 !important;

    .title {
      font-size: 1rem !important;
    }
    .speakers {
      font-size: 0.875rem !important;

      .speakersRow {
        flex-direction: column !important;
        .speaker {
          width: 100% !important;
        }
      }
    }
  }
  .rightCardActive {
    padding: 1.25rem !important;
  }
  .eventwrapper {
    flex-direction: column !important;
  }
  .activeActions {
    font-size: 0.75rem !important;
    .likeBtn {
      img {
        width: 1.25rem !important;
      }
    }
  }
}
