@import "../../styling/scss-variables";

.sidePane {
  display: flex;
  height: 80vh;
  .mainComponent {
    flex: 2;
    position: relative;
    // aspect-ratio: 16/9;
    max-width: 100vw;
  }
  .sideComponent {
    background: #000000;
  }
  .sideComponentMobile {
    display: none;
    background: rgb(0, 0, 0);
    position: fixed;
    bottom: 0px;
    width: 100%;
    transition: all 0.5s ease;
    border-radius: 1.5rem 1.5rem 0px 0px;
    z-index: 10;
    overflow-y: auto;
  }
}
.sidePaneFullScreen {
  height: 100vh !important;
}

@media only screen and (max-width: $mobile-breakpoint) {
  .sidePane {
    height: 30vh;

    .sideComponent {
      display: none !important;
    }
    .sideComponentMobile {
      display: block !important;
    }
  }
}
