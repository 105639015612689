@import "../../../styling/scss-variables";

.overlayCard {
  color: #fff;
  .row {
    display: flex;
    margin-bottom: 1.5rem;
    font-weight: bold;
    font-size: 1.25rem;
  }
  .infoRow {
    width: 80%;
    justify-content: space-between;
    .rightInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  }
  .tabs {
    min-width: 10.375rem;
    max-width: auto;
    height: 3.125rem;
    font-weight: 600;
    font-size: 1.25rem;
    color: #fff;
    border: 3px solid #eb4547;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
    padding: 0.05rem 0.2rem;
    text-transform: capitalize;
  }

  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0.75rem;
  }

  .subtitle {
    font-size: 1rem;
    font-weight: 400;
    width: 37.5rem;
    margin-bottom: 2rem;
    line-height: 1.5625rem;
  }

  .btnWrapper {
    img {
      cursor: pointer;
      margin-right: 2rem !important;
      width: unset !important;
      min-width: unset !important;
    }
  }

  .socialData {
    align-items: center;
    margin-top: 1.875rem;
    cursor: pointer;

    &:hover {
      // text-decoration: underline;
    }
    img {
      margin-right: 1rem !important;
      min-width: unset !important;
      width: 2.5rem !important;
    }
    .rot180 {
      transform: rotate(180deg);
    }
  }

  .progressInfo {
    display: flex;
    align-items: center;
  }
  .durationBar {
    height: 0.4375rem;
    background: #c4c4c4;
    border-radius: 4px;
    width: 50%;
    position: relative;
    margin-right: 1.5rem;

    .progressBar {
      position: absolute;
      background: #eb4547;
      height: 0.4375rem;
      border-radius: 4px;
    }
  }
}

.overlayCardMob {
  color: $on-primary;
  padding: 1.25rem;
  padding-bottom: 0;
  .row {
    display: flex;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.25rem;
    flex-wrap: wrap;
  }
  .infoRow {
    justify-content: space-between;
    flex-direction: column;
  }
  .tabs {
    min-width: 3.3125rem;
    max-width: auto;
    height: 0.875rem;
    font-weight: 600;
    font-size: 0.75rem;
    color: #fff;
    background: #2c4067;
    border-radius: 32px;
    display: flex;
    text-transform: capitalize;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
    margin-bottom: 0.625rem;
    padding: 0.7rem 1rem;
    box-sizing: content-box;
  }

  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.75rem;
  }

  .subtitle {
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 2rem;
    line-height: 1.25rem;
  }

  .btnWrapper {
    img {
      cursor: pointer;
      margin-right: 2rem !important;
      width: unset !important;
      min-width: unset !important;
    }
  }
  .rightInfo {
    display: flex;
    gap: 2rem;
    // justify-content: space-between;
    align-items: flex-start;
    &:last-of-type {
      margin-left: 200px !important;
    }
  }

  .ratings {
    display: flex;
    flex-direction: column;
    border: 1px solid $on-primary;
    border-radius: 5px;
    // font-weight: bold;
    align-self: center;
    padding: 0.8rem;
    // font-size: 16px;
    margin-bottom: 0.6875rem;
    > div {
      // margin-bottom: 18px;
    }
  }

  .socialData {
    span {
      span {
        display: none !important;
      }
    }
    cursor: pointer;
    display: flex;
    flex-direction: column;
    max-width: 3.125rem;
    text-align: center;
    font-weight: bold;
    font-size: 0.75rem;

    &:hover {
      text-decoration: underline;
    }
    .actionImg {
      border: 1px solid $on-primary !important;
      border-radius: 50%;
      padding: 0.875rem !important;
      position: relative !important;
      margin-bottom: 0.75rem !important;
      height: 1.25rem;
      width: 1.25rem;
    }
    .rot180 {
      transform: rotate(180deg);
    }
  }

  .progressInfo {
    display: flex;
    align-items: center;
    font-size: 0.625rem;
    margin-bottom: 2rem;
  }
  .durationBar {
    height: 0.4375rem;
    background: #c4c4c4;
    border-radius: 0.25rem;
    flex: 3;
    position: relative;
    margin-right: 1.5rem;

    .progressBar {
      position: absolute;
      background: #eb4547;
      height: 0.4375rem;
      border-radius: 4px;
    }
  }
  .playedInfo {
    flex: 1;
  }
}
@media only screen and (max-width: 1500px) {
  .overlayCard {
    .infoRow {
      width: 90%;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .overlayCardMob {
    .btnWrapper {
      img {
        height: 2.625rem;
      }
    }
  }
}

.episodesWrapper {
  .header {
    font-weight: 600;
    font-size: 2rem;
    color: $on-primary;
  }
  .episodeCard {
    height: 13.375rem;
    margin-top: 4rem;
    display: flex;
    max-width: 100vw;
    overflow: auto;
    // cursor: pointer;

    &:hover {
      transform: scale(1.01) !important;
      transition: all 0.5s ease;
    }

    .thumbnail {
      position: relative;
      margin-right: 3.5rem;
      cursor: pointer;
      img {
        height: 100%;
        border-radius: 8px 50px 0 0;
      }
    }

    .playBtn {
      position: absolute;
      inset: unset !important;
      width: 20% !important;
      bottom: 0 !important;
      left: 0 !important;

      height: unset !important;
      min-width: unset !important;
      min-height: unset !important;
      cursor: pointer;
    }
    .videoType {
      position: absolute;
      bottom: 1%;
      right: 1%;
      height: 2rem !important;
      width: 2rem;
      object-fit: contain;
    }

    .details {
      display: flex;
      flex-direction: column;
      color: $on-primary;
      justify-content: space-between;
      user-select: none;

      .episode {
        font-size: 1.5rem;
        font-weight: 600;
      }
      .title {
        font-size: 2rem;
        font-weight: 700;
        margin-top: 0.675rem;
      }
      .duration {
        font-size: 1.5rem;
        color: $on-primary;
        opacity: 0.6;
        margin-top: 1.1875rem;
      }

      .btnsWrapper {
        display: flex;

        .actionBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-size: 1.25rem;
          margin-right: 1.5rem;
          background: #2c4067;
          height: 4rem;
          padding: 0 2rem;
          border-radius: 8px;
          cursor: pointer;
          color: #fff;

          &:hover {
            text-decoration: underline;
            background: #eb4547;
            transition: all 0.3s ease;
          }

          &:last-of-type {
            background: transparent;
            color: $on-primary;
          }

          img {
            margin-right: 0.6875rem !important;
            min-width: unset !important;
            width: unset !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .episodesWrapper {
    .header {
      font-size: 0.875rem !important;
    }
    .episodeCard {
      height: auto !important;
      margin-top: 1rem !important;
      .thumbnail {
        min-width: 7.5rem;
        max-width: 7.5rem;
        height: 4.25rem;
        margin-right: 0.75rem !important;
        img {
          border-radius: 4px 25px 0 0;
        }

        .episodeImg {
          width: 7.5rem;
          height: 4.25rem;
        }
      }
      .details {
        .episode {
          font-size: 0.675rem !important;
          opacity: 0.6;
        }
        .title {
          font-size: 0.75rem !important;
          margin-top: 0.5rem !important;
        }
        .duration {
          font-size: 0.625rem;
          margin-top: 0.3125rem !important;
        }
        .btnsWrapper {
          margin-top: 0.3rem;
          // display: none !important;
          .actionBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 0.65rem;
            margin-right: 0.75rem;
            background: #2c4067;
            height: 2rem;
            padding: 0 0.7rem;
            border-radius: 4px;
            cursor: pointer;
            color: #fff;
            img {
              width: 1.5rem !important;
            }
          }
        }
      }
    }
  }
}

.speakersWrapper {
  display: flex;
  flex-wrap: wrap;

  .speakerAndInfo {
    display: flex;
    flex-direction: column;
    width: 22%;
    margin-right: 3%;
    position: relative;
    &:nth-last-child(1) {
      margin-right: 0px;
    }
  }
  .speakerCard {
    width: 100%;
    height: 31.25rem;
    border: 1px solid $on-primary;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 20.625rem;
    margin-bottom: 3rem;
    padding: 2.9375rem;
    padding-bottom: 1.875rem;
    border-radius: 16px;
    font-weight: normal;
    font-size: 1.125rem;
    color: $on-primary;
    position: relative;

    &::before {
      content: "";
      width: 2.3125rem;
      height: 2.3125rem;
      background-color: inherit;
      position: absolute;
      bottom: -2rem;
      left: 50%;
      transform: rotate(45deg) translateX(-50%);
    }

    &:hover {
      // background: #eb4547;
      transform: scale(1.01) !important;
      transition: all 0.2s ease;
      color: #fff;

      // .name,
      // .viewInfoBtn {
      //   text-decoration: underline;
      //   text-underline-offset: 3px;
      // }
    }
    .name,
    .viewInfoBtn {
      text-align: center;
    }

    .name {
      font-size: 1.25rem;
      font-weight: 600;
      margin: 0.4rem 0;
    }

    .speakerImg {
      width: 100%;
      height: auto;
      margin-bottom: 1.125rem;
      border-radius: 50%;
      background-color: #000;
    }
    .infoList {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      margin: 0;
      color: #c5c2c2;
      font-size: 1rem;
      font-weight: 400;

      .infoItem {
        list-style: none;
        padding: 0.125rem;
        text-align: center;
      }
    }
    .viewInfoBtn {
      margin-top: 2rem;
      cursor: pointer;
      user-select: none;
      text-align: center;
    }
  }
  .mobArrow {
    display: none;
  }
  .arrow {
    width: 0.875rem;
    height: 0.875rem;
    border: 2px solid $on-primary;
    border-bottom: 2px solid transparent;
    border-right: 2px solid transparent;
    transform: rotate(45deg);
  }
  .speakerInfo {
    position: relative;
    // bottom: -15.75rem;
    // overflow-x: hidden;
    background: #eb4547;
    margin-top: -0.8125rem;
    margin-bottom: 2rem;
    width: 100vw;
    min-height: 0.9375rem;
    padding: 1.8125rem 50%;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 2.5rem;
    z-index: 1;
    color: #fff;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .closeIcon {
        cursor: pointer;
        z-index: 5;
      }
    }

    .detailPoints {
      display: block;
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
      padding: 0;
      margin: 2.8125rem 0;

      li {
        color: #fff;
        font-weight: normal;
        font-size: 1.25rem;
        line-height: 140%;
        margin-bottom: 2rem;
        width: 80%;
      }
    }
  }
  .speakerInfoPlaceholder {
    // height: 492px;
    box-sizing: border-box;

    &::before {
      content: "";
      width: 2.3125rem;
      height: 2.3125rem;
      background-color: #eb4547;
      position: absolute;
      transform: rotate(45deg);
      top: 30.125rem;
      left: 40%;
    }
  }
}
.speakerDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}
@media only screen and (max-width: $mobile-breakpoint) {
  .speakersWrapper {
    flex-direction: column !important;

    .speakerAndInfo {
      width: 100% !important;
      transform: translateX(0) !important;
    }

    .mobArrow {
      display: block !important;
    }

    .speakerCard {
      flex-direction: row !important;
      padding: 1rem !important;
      margin-right: 0 !important;
      max-width: none !important;
      max-height: 7.375rem !important;
      margin-bottom: 1rem !important;

      &::before {
        content: "";
        left: 90%;
        transform: rotate(45deg) translateX(-90%);
      }

      &:hover {
        // background: #eb4547;
        transform: scale(1.01) !important;
        transition: all 0.2s ease;
        color: #fff;

        .name,
        .viewInfoBtn {
          text-decoration: underline;
          text-underline-offset: 3px;
        }
      }
      .name,
      .viewInfoBtn {
        text-align: left;
      }
      .speakerImg {
        min-width: 4rem !important;
        max-width: 4rem !important;
        min-height: 4rem !important;
        max-height: 4rem !important;
        margin: auto 0 !important;
        margin-right: 1rem !important;
      }
      .speakerDetails {
        width: 100% !important;
        align-items: flex-start;
      }
    }
    .name {
      font-size: 1rem !important;
      margin-bottom: 0.25rem !important;
    }
    .infoList {
      font-size: 0.875rem !important;
      align-items: flex-start !important;
      .infoItem {
        text-align: left !important;
      }
    }
    .viewInfoBtn {
      display: none !important;
    }
  }
  .speakerInfo {
    margin-left: 0 !important;
    max-width: calc(100vw - 2.5rem) !important;
    padding: 1.25rem !important;
    margin-top: 0.6875rem !important;
    font-size: 1rem !important;
    &::before {
      left: 1.5rem !important;
    }

    .detailPoints {
      column-count: unset !important;
      padding: 1.25rem !important;
      margin: 0 !important;
      li {
        font-size: 0.75rem !important;
        width: 100% !important;
        margin-bottom: 1.25rem !important;
      }
    }
  }
}

.detailsWrapper {
  color: $on-primary;
  width: 80%;

  .details {
    font-weight: normal;
    font-size: 1.25rem;

    ul > li {
      padding: 0.3125rem 0;
    }
  }
}

.customTabHeader {
  margin-bottom: 3.25rem !important;
  background-color: #161616;
  height: 4.4375rem;
  user-select: none;
  position: relative;

  .customTabClass {
    border-bottom-color: #eb4547 !important;
    margin-right: 0 !important;
    // height: 50%;
    padding-top: 1.5625rem;
    border-bottom-width: 4px !important;
    padding-bottom: 0.3125rem !important;
    &:first-of-type {
      margin-right: 5rem !important;
    }
  }
}

.customTabContent {
  height: calc(100% - 11rem) !important;
}

.input {
  position: absolute;
  bottom: 4%;
  width: 98%;
  border: 1px solid #c2cedb;
  box-sizing: border-box;
  border-radius: 4px;
  height: 4.6875rem;

  input {
    height: 100%;
    background-color: #000;
    padding: 1.5rem;
    box-sizing: border-box;
    border: none;
    width: calc(100% - 6.5625rem);
    color: #fff;

    &:focus {
      outline-color: transparent;
      outline-style: none;
    }
  }
  button {
    background: #2c4067;
    border-radius: 8px;
    width: 6.25rem;
    height: 3.6875rem;
    color: #fff;
    &:disabled {
      color: #ccc;
    }
  }
}

.notesContainer {
  height: 100%;
  position: relative;
}
.notesWrapper {
  position: relative;
  color: #fff;
  overflow-y: auto;
  height: calc(100% - 8rem);
  padding-right: 0.5rem;
  padding-top: 1rem;

  &::-webkit-scrollbar {
    display: block !important;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 1.5rem;
    }
  }

  .noteBox {
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: normal;
    font-size: 1rem;
    position: relative;

    .timeStamp {
      font-size: 0.875rem;
      margin-bottom: 1rem;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.5);

      &:hover {
        text-decoration: underline;
      }
    }
    .actionBtns {
      position: absolute;
      display: flex;
      top: -12%;
      right: 7%;
      background: #000;

      .deleteBtn {
        cursor: pointer;
      }
      img {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  .transcriptBox {
    display: flex;
    margin-bottom: 2rem;

    .timeStamp {
      font-size: 0.875rem;
      margin-right: 1.4375rem;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .detailsWrapper {
    h1 {
      font-size: 1rem !important;
      font-weight: 600;
    }

    .details {
      font-size: 0.75rem !important;

      ul > li {
        padding: 0.3125rem 0;
      }
    }
  }
  .customTabHeader {
    margin-bottom: 1rem !important;
  }
  .customTabContent {
    height: calc(100% - 9rem) !important;
  }

  .notesWrapper {
    position: relative;
    color: #fff;
    overflow-y: auto;
    height: calc(100% - 8rem);
    padding-right: 0.5rem;

    &::-webkit-scrollbar {
      display: block !important;
    }

    .loader {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 1.5rem;
      }
    }

    .noteBox {
      .timeStamp {
        margin-bottom: 0.5rem;
      }
    }
  }
}
