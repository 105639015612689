.addToCalendar {
  position: relative;
}
.bannerBox__btn {
  margin-top: 0rem !important;
}

.bannerBox__btn:focus {
  outline: none;
}

.dropdownStyles {
  position: absolute;
  border: none;
  width: 100%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.582);
  z-index: -1;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  bottom: 0;
  padding-top: 0.3125rem;
  transform: translate(0, calc(100% - 0.3125rem));
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  animation: scaleDown 1s forwards ease;
  transform-origin: top;
  padding-bottom: 1rem;
}
.dropdownStyles a:link,
.dropdownStyles a:visited {
  padding: 0.5rem 0.65rem;
  border-top: solid 2px #eb454773;
}

.linkStyles {
  text-decoration: none;
  display: block;
  color: #015189;
  font-size: 1.15rem;
  text-align: center;
  padding: 0.5rem;
  border-bottom: 0.15rem solid #015189;
}

.linkStyles:hover {
  background: #015189;
  color: white;
}

@keyframes scaleDown {
  0% {
    transform: translate(0, calc(100% - 0.3125rem)) scaleY(0);
  }
  100% {
    transform: translate(0, calc(100% - 0.3125rem)) scaleY(1);
  }
}
