@import "../../../../styling/scss-variables";

.personalDetail-form {
  .profiledetails {
    margin-top: 1rem;
    #address {
      position: relative;
    }
    // .autocomplete-dropdown-container {
    //   color: #888888;
    //   font-weight: normal;
    //   display: block;
    //   white-space: nowrap;
    //   min-height: 1.2em;
    //   padding: 0px 2px 1px;
    //   // background-image: url(../../assets/images/down.png);
    //   //   background-repeat: no-repeat;
    //   //   background-position-x: 97%;
    //   //   background-position-y: 50%;
    //   cursor: pointer;
    //   &:not(:first-of-type) {
    //     color: #fff;
    //     background: #2c4067;
    //   }
    // }

    .input-group {
      margin-bottom: 1.4rem;
      .dropdown {
      }

      label {
        font-weight: 700;
        font-size: 1.4rem;
      }
      select,
      input {
        margin-top: 1rem;
      }
      select {
        color: #888888;

        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url(../../../../assets/images/down.png);
        background-repeat: no-repeat;
        background-position-x: 97%;
        background-position-y: 50%;
        cursor: pointer;

        option:not(:first-of-type) {
          color: #fff;
          background: rgba(44, 64, 103, 1);
        }
      }
    }

    .register-btn {
      margin-top: 0;
      width: 50%;
    }
  }
  .premiumBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > :first-child {
      display: flex;
      margin-bottom: 1.5rem;

      img {
        margin-right: 1rem;
        height: 1.2rem;
        width: 1.2rem;
      }

      span {
        color: $primary-color2;
      }
    }
  }
}
