@import "../../styling/scss-variables";

.mainContainer {
  background-color: $primary-color;
  color: #fff;
  overflow: hidden;
  background-image: url(../../assets/images/converseBg.jpg);

  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  height: 100vh;

  .container {
    position: relative;
    height: 100vh;

    .containerOverlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
      display: flex;
      flex-direction: column;
      padding: 2% 6%;
      width: 90%;
      -webkit-box-shadow: inset 500px 29px 147px 136px rgba(10, 35, 71, 0.8);
      -moz-box-shadow: inset 500px 29px 147px 136px rgba(10, 35, 71, 0.8);
      box-shadow: inset 500px 29px 147px 136px rgba(10, 35, 71, 0.8);
      .converseImg {
        position: absolute;
        width: 25rem;
        // height: 14.8125rem;
        top: 15%;
        right: 5%;
      }
      .converseImg2 {
        width: 10rem;
        top: 20%;
        right: 5%;
      }

      .brandLogo {
        font-size: 2.8rem;
        display: flex;
        justify-content: space-between;
        // margin-right: 3rem;
        // font-weight: 600;
        cursor: pointer;
        align-items: center;

        img {
          width: unset !important;
          // height: 65%;
          min-width: unset !important;
          margin-right: 1.125rem !important;
          max-width: 8rem;
        }
      }
      .detail {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 10%;
        align-self: flex-start;
        transform: translate(0, 0);
        animation: slideLeft 0.5s forwards;

        button {
          font-size: 1.4rem;
          height: 3rem;
          font-weight: 600;
          padding: 2rem 0;
          margin-top: 0.5rem;
          flex-direction: column;
          width: 22rem;
        }
      }
    }
  }
  .mainTitle {
    font-weight: 600;
    color: $primary-color2;
    font-size: 1.5rem;
  }

  .heading {
    font-size: 2.5rem;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    padding: 0.5rem 0;
    .eshHighlights {
      width: 64%;
      object-fit: contain;
      margin-left: -1%;
      max-width: 70rem;
    }
    .eshHighlightsMobile {
      width: 82%;
      margin-top: 10%;
    }

    .date {
      font-weight: 700;
      font-size: 2.3rem;
      margin-top: 1rem;
      color: #eb4547;
    }
    .doctorDetails {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 2rem 0;
      .docDescription {
        font-weight: 500;
        font-size: 1.9rem;
        margin-bottom: 0.5rem;
      }
    }

    .remainingDoctors {
      display: flex;
      font-size: 1.5rem;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      padding: 1rem 0;
      justify-content: space-between;
      margin: 4rem 0;

      .left {
        border-right: 1px solid #fff;
      }

      .right {
        h3 {
          padding-left: 3rem;
        }
        .speakers {
          display: flex;
        }
      }

      .doctor {
        padding: 0 3rem;

        // &:nth-child(1) {
        //   padding-left: 0;
        // }

        // &:nth-child(3) {
        //   border: none;
        //   padding-right: 0;
        // }
        img {
          width: 7rem;
          height: 7rem;
          margin-bottom: 0.5rem;
        }
        .desc {
          margin-top: 0.5rem;
          font-weight: 500;
          font-size: 0.8rem;
        }
      }
    }
  }
  .carouselContent {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;
  }
  .content {
    padding: 5% 10%;

    .detailMobile {
      display: none;
    }
    .contentHeading {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        font-size: 3rem;
        font-weight: 700;
        width: 20%;
      }
    }
    .contentHeading1 {
      flex-direction: column;
      align-items: flex-start;
      h2 {
        width: 100%;
        margin: 0;
        span {
          color: #eb4547;
        }
      }
      .subHeading {
        margin-top: 1rem;
        font-size: 1.5rem;
        span {
          font-weight: 600;
        }
      }
    }
  }
}
@keyframes slideLeft {
  0% {
    transform: translateX(-140%);
  }
  100% {
    transform: translate(0, 0);
  }
}

.OTPContainer {
  color: $on-primary2;

  .box {
    background: #0a2040;
    border: 1px solid $primary-color2;
    box-sizing: border-box;
    border-radius: 1.5rem;
    padding: 2.6rem;
    min-width: 35%;
    position: relative;
  }
}
.scroll-down {
  position: absolute;
  width: 5vw;
  height: 10vh;
  transform: scale(1);
  top: 95%;
  // top: 96%;
  left: 50%;
  z-index: 10;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  transform: translate(-50%, -50%);
  cursor: pointer;
  animation: blink 3s linear infinite;
  // animation: cssAnimation 0s 10s forwards;
  // visibility: hidden;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.scroll-down-desktop {
  display: block;
}
.scroll-down-mobile {
  display: none;
}
// @keyframes cssAnimation {
//   to {
//     visibility: visible;
//   }
// }
// @media only screen and (max-width: 900px) {
//   .mainContainer {
//     .container {
//       .containerOverlay {
//         .detail {
//           display: none;
//         }
//       }
//     }
//     .content {
//       .detailMobile {
//         display: block;
//       }
//     }
//   }
// }

.customShareBtn {
  justify-content: center !important;
  align-items: center !important;
  gap: 0.5rem;
  margin-top: 0 !important;
  height: 2rem !important;
  cursor: pointer !important;

  &:hover {
    background-color: $primary-color2 !important;
    color: #fff !important;
  }
}
@media only screen and (max-width: 800px) {
  .mainContainer {
    background-image: url(../../assets/images/converseBgMob.png);
    background-position: right center;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .mainContainer {
    // height: 130vh;
    .container {
      position: relative;
      width: 100vw;
      background-position: center !important;

      .containerOverlay {
        width: 100%;
        padding: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        .converseImg {
          width: 60%;
          height: auto;
          top: 13%;
          left: 8%;
        }
        .converseImg2 {
          width: 10rem;
          top: 20%;
          right: 5%;
        }
        .brandLogo {
          font-size: 2.1rem;
          margin-top: 1rem;
          // margin-left: auto;
          margin-left: 5%;
          img {
            // margin-right: 1rem !important;
            max-width: 5rem;
          }
        }
        .detail {
          display: none;
        }
      }
    }
    .mainTitle {
      font-size: 1.25rem;
    }

    .heading {
      font-size: 1.875rem;

      .headerText {
        color: #eb4547;
        font-size: 3.5rem;
      }
      .eshHighlights {
        width: 90%;
      }
      .date {
        font-weight: 700;
        font-size: 1.3rem;
        margin-top: 0.5rem;
      }
      .doctorDetails {
        display: flex;
        flex-direction: column;

        align-items: flex-start;
        gap: 1.8rem;
        font-size: 1.4rem;
        padding: 1rem 0;
        .docDescription {
          font-weight: 300;
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
      }
    }
    .carouselContent {
      font-size: 1.25rem;
      padding-right: 1rem;
    }
    .content {
      z-index: 2;
      position: absolute;
      top: 13rem;
      padding: 2% 8%;
      padding-bottom: 3rem;
      align-items: center;

      .detailMobile {
        display: block;
        .heading {
          font-size: 2rem;
          gap: 1rem;

          .remainingDoctors {
            display: flex;
            flex-direction: column;
            font-size: 1.5rem;
            border: 0;
            padding: 1rem 0;
            justify-content: space-between;
            margin-bottom: 1rem;
            gap: 1rem;

            h4 {
              margin: 0;
            }

            .doctor {
              display: flex;
              gap: 1rem;
              padding: 0;
              border: 0;

              img {
                width: 7rem;
                height: 7rem;
                margin-bottom: 0.5rem;
              }
              .desc {
                margin-top: 0.5rem;
                font-weight: 500;
                font-size: 0.8rem;
              }
            }

            & > :nth-child(2) {
              border-top: 1px solid #fff;
              border-bottom: 1px solid #fff;
              padding: 2rem 0;
              margin-bottom: 2rem;
            }
            & > :nth-child(4) {
              border-top: 1px solid #fff;
              padding-top: 2rem;
              padding-bottom: 1rem;
            }
            & > :nth-child(5) {
              border-bottom: 1px solid #fff;
              padding-bottom: 2rem;
            }
          }
        }
        button {
          margin-top: 0.5rem;
          font-size: 1rem;
          padding: 1.5rem;
          font-weight: 600;
          flex-direction: column;
          // position: fixed;
          bottom: 3rem;
        }
      }
      .contentHeading {
        // flex-direction: column-reverse;
        align-items: flex-start;
        margin-bottom: 1rem;

        h2 {
          font-size: 1.5rem;
          width: 100%;
        }
        .subHeading {
          font-size: 1.25rem;
        }
      }
      .contentHeading1 {
        margin-top: 3rem;
        flex-direction: column;
      }
    }
  }
  .scroll-down-desktop {
    display: none;
  }
  .scroll-down-mobile {
    display: block;
    width: 15vw;
    // font-size: 1.5rem;
    top: 93%;
  }
}

@media only screen and (max-width: 325px) {
  .mainContainer {
    .content {
      .detailMobile {
        .heading {
          .remainingDoctors {
            .doctor {
              img {
                width: 5rem;
                height: 5rem;
                margin-bottom: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}
